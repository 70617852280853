@font-face {
    font-display: swap;
    font-family: 'Comfortaa-VariableFont_wght';
    src: url("../src/fonts/Comfortaa/Comfortaa-VariableFont_wght.eot");
    src: url(../src/fonts/Comfortaa/Comfortaa-VariableFont_wght.eot?#iefix) format("embedded-opentype"), url(../src/fonts/Comfortaa/Comfortaa-VariableFont_wght.woff2) format("woff2"), url(../src/fonts/Comfortaa/Comfortaa-VariableFont_wght.woff) format("woff"), url(../src/fonts/Comfortaa/Comfortaa-VariableFont_wght.svg#Comfortaa-VariableFont_wght) format("svg"), url(../src/fonts/Comfortaa/Comfortaa-VariableFont_wght.otf) format("otf");
}

@font-face {
    font-display: swap;
    font-family: 'Comfortaa-Light';
    src: url(../src/fonts/Comfortaa/Comfortaa-Light.eot);
    src: url(../src/fonts/Comfortaa/Comfortaa-Light.eot?#iefix) format("embedded-opentype"), url(../src/fonts/Comfortaa/Comfortaa-Light.woff2) format("woff2"), url(../src/fonts/Comfortaa/Comfortaa-Light.woff) format("woff"), url(../src/fonts/Comfortaa/Comfortaa-Light.otf) format("otf"), url(../src/fonts/Comfortaa/Comfortaa-Light.svg#Comfortaa-Light) format("svg");
}

@font-face {
    font-display: swap;
    font-family: 'Comfortaa-Regular';
    src: url(../src/fonts/Comfortaa/Comfortaa-Regular.eot);
    src: url(../src/fonts/Comfortaa/Comfortaa-Regular.eot?#iefix) format("embedded-opentype"),
        url(../src/fonts/Comfortaa/Comfortaa-Regular.otf) format("otf"),
        url(../src/fonts/Comfortaa/Comfortaa-Regular.svg#Comfortaa-Regular) format("svg"),
        url(../src/fonts/Comfortaa/Comfortaa-Regular.woff) format("woff"),
        url(../src/fonts/Comfortaa/Comfortaa-Regular.woff2) format("woff2");
}

@font-face {
    font-display: swap;
    font-family: 'Comfortaa-Bold';
    src: url(../src/fonts/Comfortaa/Comfortaa-Bold.eot);
    src: url(../src/fonts/Comfortaa/Comfortaa-Bold.eot?#iefix) format("embedded-opentype"),
        url(../src/fonts/Comfortaa/Comfortaa-Bold.otf) format("otf"),
        url(../src/fonts/Comfortaa/Comfortaa-Bold.svg#Comfortaa-Bold) format("svg"),
        url(../src/fonts/Comfortaa/Comfortaa-Bold.woff) format("woff"),
        url(../src/fonts/Comfortaa/Comfortaa-Bold.woff2) format("woff2");
}

@font-face {
    font-display: swap;
    font-family: 'Roboto-Light';
    src: url(../src/fonts/Roboto/Roboto-Light.eot);
    src: url(../src/fonts/Roboto/Roboto-Light.eot?#iefix) format("embedded-opentype"),
        url(../src/fonts/Roboto/Roboto-Light.otf) format("otf"),
        url(../src/fonts/Roboto/Roboto-Light.svg#Roboto-Light) format("svg"),
        url(../src/fonts/Roboto/Roboto-Light.woff) format("woff"),
        url(../src/fonts/Roboto/Roboto-Light.woff2) format("woff2");
}

@font-face {
    font-display: swap;
    font-family: 'Roboto-Regular';
    src: url(../src/fonts/Roboto/Roboto-Regular.eot);
    src: url(../src/fonts/Roboto/Roboto-Regular.eot?#iefix) format("embedded-opentype"), url(../src/fonts/Roboto/Roboto-Regular.woff) format("woff"), url(../src/fonts/Roboto/Roboto-Regular.woff2) format("woff2"), url(../src/fonts/Roboto/Roboto-Regular.otf) format("otf"), url(../src/fonts/Roboto/Roboto-Regular.svg#Roboto-Regular) format("svg");
}

@font-face {
    font-display: swap;
    font-family: 'Roboto-Medium';
    src: url(../src/fonts/Roboto/Roboto-Medium.eot);
    src: url(../src/fonts/Roboto//Roboto-Medium.eot?#iefix) format("embedded-opentype"), url(../src/fonts/Roboto/Roboto-Medium.otf) format("otf"), url(../src/fonts/Roboto/Roboto-Medium.svg#Roboto-Medium) format("svg"), url(../src/fonts/Roboto/Roboto-Medium.woff) format("woff"), url(../src/fonts/Roboto/Roboto-Medium.woff2) format("woff2");
}

@font-face {
    font-display: swap;
    font-family: 'Roboto-Bold';
    src: url(../src/fonts/Roboto/Roboto-Bold.eot);
    src: url(../src/fonts/Roboto/Roboto-Bold.eot?#iefix) format("embedded-opentype"), url(../src/fonts/Roboto/Roboto-Bold.otf) format("otf"), url(../src/fonts/Roboto/Roboto-Bold.svg#Roboto-Bold) format("svg"), url(../src/fonts/Roboto/Roboto-Bold.woff) format("woff"), url(../src/fonts/Roboto/Roboto-Bold.woff2) format("woff2");
}

@font-face {
    font-display: swap;
    font-family: 'Roboto-Thin';
    src: url(../src/fonts/Roboto/Roboto-Thin.eot);
    src: url(../src/fonts/Roboto/Roboto-Thin.eot?#iefix) format("embedded-opentype"),
        url(../src/fonts/Roboto/Roboto-Thin.woff) format("woff"),
        url(../src/fonts/Roboto/Roboto-Thin.svg#Roboto-Thin) format("svg"),
        url(../src/fonts/Roboto/Roboto-Thin.otf) format("otf"),
        url(../src/fonts/Roboto/Roboto-Thin.woff2) format("woff2");
}

@font-face {
    font-display: swap;
    font-family: 'Oswald-Bold';
    src: url(../src/fonts/Oswald/Oswald-Bold.eot);
    src: url(../src/fonts/Oswald/Oswald-Bold.eot?#iefix) format("embedded-opentype"),
        url(../src/fonts/Oswald/Oswald-Bold.otf) format("otf"),
        url(../src/fonts/Oswald/Oswald-Bold.svg#Oswald-Bold) format("svg"),
        url(../src/fonts/Oswald/Oswald-Bold.woff) format("woff"),
        url(../src/fonts/Oswald/Oswald-Bold.woff2) format("woff2");
}

@font-face {
    font-display: swap;
    font-family: 'Oswald-Regular';
    src: url(../src/fonts/Oswald/Oswald-Regular.eot);
    src: url(../src/fonts/Oswald/Oswald-Regular.eot?#iefix) format("embedded-opentype"),
        url(../src/fonts/Oswald/Oswald-Regular.otf) format("otf"),
        url(../src/fonts/Oswald/Oswald-Regular.svg#Oswald-Regular) format("svg"),
        url(../src/fonts/Oswald/Oswald-Regular.woff) format("woff"),
        url(../src/fonts/Oswald/Oswald-Regular.woff2) format("woff2");
}