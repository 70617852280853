.nav_____mobile {
  box-sizing: border-box;
  position: fixed;
  display: none !important;
  margin: 0;
  padding: 0;
  width: 42px;
  height: auto;
  z-index: 2000;
}
.nav_____mobile .title_____mobile {
  position: relative;
  width: 42px;
  display: block;
  margin: 0 0 5px 0;
  padding: 0;
  height: auto;
  font-family: "Roboto-Regular";
  font: 1em sans-serif;
  text-align: center;
  z-index: 180;
  color: #828794;
  cursor: pointer;
}
.nav_____mobile .open_____title_____mobile {
  position: relative;
  width: 42px;
  display: block;
  margin: 0 0 5px 0;
  padding: 0;
  height: auto;
  font-family: "Roboto-Regular";
  text-align: center;
  z-index: 400;
  color: #ffffff;
  cursor: pointer;
}
.nav_____mobile .nav_____mobile____box {
  position: relative;
  width: 42px;
  height: 42px;
  z-index: 180;
  cursor: pointer;
}
.nav_____mobile .nav_____mobile____box div {
  display: block;
  margin: 0;
  padding: 0;
  width: 42px;
  height: 1px;
  z-index: -10;
  text-align: center;
  position: relative;
}
.nav_____mobile .nav_____mobile____box div span {
  display: block;
  position: relative;
  left: 0;
  display: block;
  width: 42px;
  height: 0.05em;
  background: #98C01E;
  transition: all 0.3s ease-in-out;
}
.nav_____mobile .nav_____mobile____box .cl____box {
  display: block;
  position: absolute;
  width: 42px;
  height: 42px;
  z-index: 10;
  background: transparent;
}
.nav_____mobile .nav_____mobile____box .top____box {
  margin-bottom: 10px;
}
.nav_____mobile .nav_____mobile____box .top____box span {
  float: left;
}
.nav_____mobile .nav_____mobile____box .center____box {
  margin-bottom: 10px;
  text-align: center;
}
.nav_____mobile .nav_____mobile____box .center____box span {
  display: block;
  margin: 0 auto;
}
.nav_____mobile .nav_____mobile____box .center____box .right {
  display: none;
}
.nav_____mobile .nav_____mobile____box .bottom____box {
  margin-bottom: 0;
}
.nav_____mobile .nav_____mobile____box .bottom____box span {
  float: right;
}
.nav_____mobile .hover_____nav_____mobile____box {
  position: relative;
  width: 42px;
  height: 42px;
  z-index: 400;
  cursor: pointer;
}
.nav_____mobile .hover_____nav_____mobile____box div {
  display: block;
  margin: 0;
  padding: 0;
  width: 42px;
  height: 2px;
  z-index: -10;
  text-align: center;
  position: relative;
}
.nav_____mobile .hover_____nav_____mobile____box div span {
  display: block;
  position: relative;
  left: 0;
  display: block;
  width: 42px;
  height: 4px;
  background: #FF664D;
}
.nav_____mobile .hover_____nav_____mobile____box .cl____box {
  display: block;
  position: absolute;
  width: 42px;
  height: 42px;
  z-index: 10;
  background: transparent;
}
.nav_____mobile .hover_____nav_____mobile____box .top____box {
  margin-bottom: 10px;
}
.nav_____mobile .hover_____nav_____mobile____box .top____box span {
  float: left;
  width: 22px;
}
.nav_____mobile .hover_____nav_____mobile____box .center____box {
  margin-bottom: 10px;
  text-align: center;
}
.nav_____mobile .hover_____nav_____mobile____box .center____box span {
  display: block;
  margin: 0 auto;
}
.nav_____mobile .hover_____nav_____mobile____box .bottom____box {
  margin-bottom: 0;
}
.nav_____mobile .hover_____nav_____mobile____box .bottom____box span {
  float: right;
  width: 22px;
}
.nav_____mobile .open_____nav_____mobile____box {
  position: relative;
  width: 42px;
  height: 42px;
  z-index: 400;
  cursor: pointer;
}
.nav_____mobile .open_____nav_____mobile____box div {
  display: block;
  margin: 0;
  padding: 0;
  width: 42px;
  height: 1px;
  z-index: -10;
  text-align: center;
  position: relative;
}
.nav_____mobile .open_____nav_____mobile____box div span {
  display: block;
  position: relative;
  left: 0;
  display: block;
  width: 42px;
  height: 0.05em;
  background: #ffffff;
}
.nav_____mobile .open_____nav_____mobile____box .cl____box {
  display: block;
  position: absolute;
  width: 42px;
  height: 42px;
  z-index: 10;
  background: transparent;
}
.nav_____mobile .open_____nav_____mobile____box .top____box {
  margin-bottom: 10px;
}
.nav_____mobile .open_____nav_____mobile____box .top____box span {
  height: 4px;
  opacity: 0;
  transform: rotate(45deg);
}
.nav_____mobile .open_____nav_____mobile____box .center____box {
  margin-bottom: 10px;
  text-align: center;
  position: relative;
}
.nav_____mobile .open_____nav_____mobile____box .center____box .left {
  position: relative;
  display: block;
  margin: 0 auto;
  z-index: 1;
  transform: rotate(-45deg);
}
.nav_____mobile .open_____nav_____mobile____box .center____box .right {
  position: relative;
  top: -1px;
  display: block;
  margin: 0 auto;
  z-index: 2;
  transform: rotate(45deg);
}
.nav_____mobile .open_____nav_____mobile____box .bottom____box {
  margin-bottom: 0;
}
.nav_____mobile .open_____nav_____mobile____box .bottom____box span {
  height: 4px;
  opacity: 0;
  transform: rotate(45deg);
}
.nav_____mobile .bg_____mobile {
  display: block;
  position: fixed;
  z-index: 250;
  border-radius: 100%;
  border-style: solid;
  border-width: 0;
  border-color: #229038;
  width: 1px;
  height: 1px;
  left: 100%;
  top: 0%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
.nav_____mobile .open____bg_____mobile {
  display: block;
  border-width: 250vw;
  opacity: 1;
  z-index: 250;
  transition: all 0.6s ease-in-out;
}

.link_____mobile {
  display: block;
  position: fixed;
  width: 280px;
  height: 60%;
  padding: 15% 0 0 0;
  left: 50%;
  margin-left: -140px;
  top: 10%;
  z-index: -1;
  opacity: 0;
  overflow: hidden;
}
.link_____mobile a {
  text-align: center;
  display: inline-block;
  width: 100%;
  padding: 10px 0 40px 0;
  position: relative;
  height: auto;
  font-family: "Comfortaa-Regular";
  color: #fff;
  font-size: 0.9rem;
  text-decoration: none;
  opacity: 0;
  overflow: hidden;
}
.link_____mobile a:after {
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  background: #fff;
  z-index: 1;
}
.link_____mobile a:hover {
  color: #FF664D;
}
.link_____mobile .active {
  color: #f6f6f6;
  letter-spacing: 2px;
  transition: all 0.3s ease-in-out;
}
.link_____mobile .active:hover {
  color: #545454;
  transition: all 0.3s ease-in-out;
}

.open_____link_____mobile {
  display: block;
  position: fixed;
  width: 280px;
  height: 60%;
  padding: 0 0 0 0;
  left: 50%;
  margin-left: -140px;
  top: 10%;
  z-index: 300;
  opacity: 1;
  animation: linkanimmob 0.8s ease-in-out;
}
.open_____link_____mobile a {
  text-align: center;
  display: inline-table;
  width: 100%;
  padding: 10px 0 40px 0;
  position: relative;
  height: auto;
  font-family: "Comfortaa-Regular";
  color: #fff;
  font-size: 0.9em;
  text-decoration: none;
  opacity: 1;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}
.open_____link_____mobile a:after {
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  display: block;
  width: 0.05em;
  height: 35px;
  background: #2f3f02;
  z-index: 1;
}
.open_____link_____mobile a:hover {
  color: #333;
  letter-spacing: 2px;
  transition: all 0.2s ease-in-out;
}
.open_____link_____mobile a:last-child:after {
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  display: block;
  width: 1px;
  height: 0;
  background: #ffffff;
  z-index: 1;
}

@media (max-width: 1109px) {
  .nav_____mobile {
    box-sizing: border-box;
    position: fixed;
    right: 20px;
    top: 20px;
    display: block !important;
    margin: 0;
    padding: 0;
    width: 42px;
    height: auto;
    z-index: 5500;
  }
  .nav_____mobile .title_____mobile {
    position: relative;
    width: 42px;
    display: block;
    margin: 0 0 5px 0;
    padding: 0;
    height: auto;
    font-family: "Roboto-Regular";
    font: 1em sans-serif;
    text-align: center;
    z-index: 180;
    color: #828794;
    cursor: pointer;
  }
  .nav_____mobile .open_____title_____mobile {
    position: relative;
    width: 42px;
    display: block;
    margin: 0 0 5px 0;
    padding: 0;
    height: auto;
    font-family: "Roboto-Regular";
    text-align: center;
    z-index: 400;
    color: #ffffff;
    cursor: pointer;
  }
  .nav_____mobile .nav_____mobile____box {
    position: relative;
    width: 42px;
    height: 42px;
    z-index: 180;
    cursor: pointer;
  }
  .nav_____mobile .nav_____mobile____box div {
    display: block;
    margin: 0;
    padding: 0;
    width: 42px;
    height: 1px;
    z-index: -10;
    text-align: center;
    position: relative;
  }
  .nav_____mobile .nav_____mobile____box div span {
    display: block;
    position: relative;
    left: 0;
    display: block;
    width: 42px;
    height: 0.05em;
    background: #98C01E;
    transition: all 0.3s ease-in-out;
  }
  .nav_____mobile .nav_____mobile____box .cl____box {
    display: block;
    position: absolute;
    width: 42px;
    height: 42px;
    z-index: 10;
    background: transparent;
  }
  .nav_____mobile .nav_____mobile____box .top____box {
    margin-bottom: 10px;
  }
  .nav_____mobile .nav_____mobile____box .top____box span {
    float: left;
  }
  .nav_____mobile .nav_____mobile____box .center____box {
    margin-bottom: 10px;
    text-align: center;
  }
  .nav_____mobile .nav_____mobile____box .center____box span {
    display: block;
    margin: 0 auto;
  }
  .nav_____mobile .nav_____mobile____box .center____box .right {
    display: none;
  }
  .nav_____mobile .nav_____mobile____box .bottom____box {
    margin-bottom: 0;
  }
  .nav_____mobile .nav_____mobile____box .bottom____box span {
    float: right;
  }
  .nav_____mobile .hover_____nav_____mobile____box {
    position: relative;
    width: 42px;
    height: 42px;
    z-index: 400;
    cursor: pointer;
  }
  .nav_____mobile .hover_____nav_____mobile____box div {
    display: block;
    margin: 0;
    padding: 0;
    width: 42px;
    height: 2px;
    z-index: -10;
    text-align: center;
    position: relative;
  }
  .nav_____mobile .hover_____nav_____mobile____box div span {
    display: block;
    position: relative;
    left: 0;
    display: block;
    width: 42px;
    height: 4px;
    background: #FF664D;
  }
  .nav_____mobile .hover_____nav_____mobile____box .cl____box {
    display: block;
    position: absolute;
    width: 42px;
    height: 42px;
    z-index: 10;
    background: transparent;
  }
  .nav_____mobile .hover_____nav_____mobile____box .top____box {
    margin-bottom: 10px;
  }
  .nav_____mobile .hover_____nav_____mobile____box .top____box span {
    float: left;
    width: 22px;
  }
  .nav_____mobile .hover_____nav_____mobile____box .center____box {
    margin-bottom: 10px;
    text-align: center;
  }
  .nav_____mobile .hover_____nav_____mobile____box .center____box span {
    display: block;
    margin: 0 auto;
  }
  .nav_____mobile .hover_____nav_____mobile____box .bottom____box {
    margin-bottom: 0;
  }
  .nav_____mobile .hover_____nav_____mobile____box .bottom____box span {
    float: right;
    width: 22px;
  }
  .nav_____mobile .open_____nav_____mobile____box {
    position: relative;
    width: 42px;
    height: 42px;
    z-index: 400;
    cursor: pointer;
  }
  .nav_____mobile .open_____nav_____mobile____box div {
    display: block;
    margin: 0;
    padding: 0;
    width: 42px;
    height: 1px;
    z-index: -10;
    text-align: center;
    position: relative;
  }
  .nav_____mobile .open_____nav_____mobile____box div span {
    display: block;
    position: relative;
    left: 0;
    display: block;
    width: 42px;
    height: 0.05em;
    background: #ffffff;
  }
  .nav_____mobile .open_____nav_____mobile____box .cl____box {
    display: block;
    position: absolute;
    width: 42px;
    height: 42px;
    z-index: 10;
    background: transparent;
  }
  .nav_____mobile .open_____nav_____mobile____box .top____box {
    margin-bottom: 10px;
  }
  .nav_____mobile .open_____nav_____mobile____box .top____box span {
    height: 4px;
    opacity: 0;
    transform: rotate(45deg);
  }
  .nav_____mobile .open_____nav_____mobile____box .center____box {
    margin-bottom: 10px;
    text-align: center;
    position: relative;
  }
  .nav_____mobile .open_____nav_____mobile____box .center____box .left {
    position: relative;
    display: block;
    margin: 0 auto;
    z-index: 1;
    transform: rotate(-45deg);
  }
  .nav_____mobile .open_____nav_____mobile____box .center____box .right {
    position: relative;
    top: -1px;
    display: block;
    margin: 0 auto;
    z-index: 2;
    transform: rotate(45deg);
  }
  .nav_____mobile .open_____nav_____mobile____box .bottom____box {
    margin-bottom: 0;
  }
  .nav_____mobile .open_____nav_____mobile____box .bottom____box span {
    height: 4px;
    opacity: 0;
    transform: rotate(45deg);
  }
  .nav_____mobile .bg_____mobile {
    display: block;
    position: fixed;
    z-index: 250;
    border-radius: 100%;
    border-style: solid;
    border-width: 0;
    border-color: #229038;
    width: 1px;
    height: 1px;
    left: 100%;
    top: 0%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }
  .nav_____mobile .open____bg_____mobile {
    display: block;
    border-width: 250vw;
    opacity: 1;
    z-index: 250;
    transition: all 0.6s ease-in-out;
  }
  .open_____link_____mobile {
    display: block;
    position: absolute;
    width: 280px;
    height: 60%;
    padding: 0 0 0 0;
    left: 50%;
    margin-left: -140px;
    top: 10%;
    z-index: 6000;
    opacity: 1;
    animation: linkanimmob 0.6s ease-in-out;
  }
  .open_____link_____mobile a {
    text-align: center;
    display: inline-table;
    width: 100%;
    padding: 5px 0 30px 0;
    position: relative;
    height: auto;
    font-family: "Comfortaa-Regular";
    color: #fff;
    font-size: 0.9em;
    text-decoration: none;
    opacity: 1;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
  }
  .open_____link_____mobile a:after {
    position: absolute;
    left: 50%;
    top: 50%;
    content: "";
    display: block;
    width: 0.05em;
    height: 25px;
    background: #2f3f02;
    z-index: 1;
  }
  .open_____link_____mobile a:hover {
    color: #333;
    letter-spacing: 2px;
    transition: all 0.2s ease-in-out;
  }
  .open_____link_____mobile a:last-child:after {
    position: absolute;
    left: 50%;
    top: 50%;
    content: "";
    display: block;
    width: 1px;
    height: 0;
    background: #ffffff;
    z-index: 1;
  }
}
@keyframes linkanimmob {
  0% {
    display: block;
    position: fixed;
    width: 280px;
    height: 60%;
    padding: 15% 0 0 0;
    left: 50%;
    margin-left: -140px;
    top: -20%;
    opacity: 0.2;
  }
  15% {
    display: block;
    position: fixed;
    width: 280px;
    height: 60%;
    padding: 15% 0 0 0;
    left: 50%;
    margin-left: -140px;
    top: 2%;
    opacity: 0.3;
  }
  30% {
    display: block;
    position: fixed;
    width: 280px;
    height: 60%;
    padding: 15% 0 0 0;
    left: 50%;
    margin-left: -140px;
    top: 10%;
    opacity: 0.5;
  }
  100% {
    display: block;
    position: fixed;
    width: 280px;
    height: 60%;
    padding: 15% 0 0 0;
    left: 50%;
    margin-left: -140px;
    top: 2%;
    opacity: 1;
  }
}/*# sourceMappingURL=NavMob.css.map */