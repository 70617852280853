.bgvideoout {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  margin-top: 0px;
  transform: translate(-50%, -50%);
  width: 72px;
  height: 72px;
  background-color: rgba(67, 67, 67, 0.53);
  background-image: url("../img/play.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 15px;
  border-radius: 100%;
  box-shadow: 3px 3px 18px 0 rgba(67, 67, 67, 0.33);
  cursor: pointer;
  overflow: hidden;
  transition: all 0.6s ease-in-out;
}
.bgvideoout:hover {
  box-shadow: 3px 3px 18px 0 rgba(67, 67, 67, 0.63);
}

.bgvideoopen {
  position: fixed;
  display: block;
  margin-top: 0px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150vw;
  height: 150vw;
  background-color: #fff;
  background-image: none;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 15px;
  border-radius: 100%;
  box-shadow: none;
  cursor: default;
  transition: all 0.6s ease-in-out;
  z-index: 4000;
}
.bgvideoopen:hover {
  box-shadow: none;
}

.poster-video {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 1200;
  opacity: 0;
  overflow: hidden;
}

.bordradius {
  transition: all 0.3s ease-in-out;
  border-radius: 0;
}

.video {
  position: absolute;
  display: inline-block;
  width: 100%;
  height: auto;
  top: 50%;
  left: calc(50% - 10px);
  transform: translate(-50%, -50%);
}

.button-intro {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  background-color: rgba(152, 192, 30, 0.53);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 15px;
  border-radius: 100%;
  box-shadow: 3px 3px 18px 0 rgba(67, 67, 67, 0.33);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  z-index: 5300;
}
.button-intro:hover {
  width: 68px;
  height: 68px;
  background-color: rgba(152, 192, 30, 0.73);
  background-size: 12px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 0px 2px 0 rgb(67, 67, 67);
}

.button-close {
  position: fixed;
  display: block;
  top: 20px;
  right: 20px;
  width: 0px;
  height: 0px;
  background-color: rgba(152, 192, 30, 0.53);
  background-image: url("../img/close-v.svg");
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 100%;
  box-shadow: 3px 3px 18px 0 rgba(67, 67, 67, 0.33);
  cursor: pointer;
  z-index: 5300;
}

.button-close-active {
  position: absolute;
  display: block;
  top: 20px;
  right: 20px;
  width: 58px;
  height: 58px;
  background-color: rgba(152, 192, 30, 0.53);
  background-image: url("../img/close-v.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 15px;
  border-radius: 100%;
  box-shadow: 3px 3px 18px 0 rgba(67, 67, 67, 0.33);
  cursor: pointer;
  z-index: 5300;
}
.button-close-active:hover {
  background-color: rgba(152, 192, 30, 0.73);
  background-size: 12px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 0px 2px 0 rgb(67, 67, 67);
}

.buttonIntro-play {
  background-image: url("../img/play.svg");
}

.buttonIntro-pause {
  background-image: url("../img/pause.svg");
}

@media (max-width: 750px) {
  .bgvideoout {
    position: absolute;
    display: block;
    top: calc(50% - 40px);
    left: 50%;
    margin-top: 60px;
    transform: translate(-50%, -50%);
    width: 52px;
    height: 52px;
    background-color: rgba(67, 67, 67, 0.53);
    background-image: url("../img/play.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 10px;
    border-radius: 100%;
    box-shadow: 3px 3px 18px 0 rgba(67, 67, 67, 0.33);
    cursor: pointer;
    overflow: hidden;
    transition: all 0.6s ease-in-out;
  }
  .bgvideoout:hover {
    box-shadow: 3px 3px 18px 0 rgba(67, 67, 67, 0.63);
  }
  .bgvideoopen {
    position: absolute;
    display: block;
    margin-top: 0px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 250vw;
    height: 250vw;
    background-color: #fff;
    background-image: none;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 15px;
    border-radius: 100%;
    box-shadow: none;
    cursor: default;
    transition: all 0.6s ease-in-out;
    z-index: 300;
    overflow: hidden;
  }
  .bgvideoopen:hover {
    box-shadow: none;
  }
  .video {
    position: absolute;
    display: inline-block;
    width: 140%;
    height: auto;
    top: 50%;
    left: calc(50% - 40px);
    transform: translate(-50%, -50%);
  }
}
@media (max-width: 350px) {
  .bgvideoout {
    position: absolute;
    display: block;
    top: calc(50% - 60px);
    left: 50%;
    margin-top: 60px;
    transform: translate(-50%, -50%);
    width: 52px;
    height: 52px;
    background-color: rgba(67, 67, 67, 0.53);
    background-image: url("../img/play.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 10px;
    border-radius: 100%;
    box-shadow: 3px 3px 18px 0 rgba(67, 67, 67, 0.33);
    cursor: pointer;
    overflow: hidden;
    transition: all 0.6s ease-in-out;
  }
  .bgvideoout:hover {
    background-color: rgba(67, 67, 67, 0.93);
    box-shadow: 3px 3px 18px 0 rgba(67, 67, 67, 0.63);
  }
}/*# sourceMappingURL=Video.css.map */