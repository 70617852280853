@charset "UTF-8";
@import "./css/_sass.scss";

:root {
  --off-white: #fff;
}

.App {
  position: relative;
  display: block;
  width: 100vw;
  height: auto;
  overflow: hidden;
}

body {
  position: relative;
  background-color: #c6c6c6;
  overflow: hidden;
}

.noscroll {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}


.nav {
  background-color: var(--off-white);
  position: fixed;
  top: 0px;
  width: 100%;
  height: 90px;
  z-index: 5000;
  box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.10);
}

.logo:hover {
  cursor: pointer;
}

.nav .nav-content {
  position: relative;
  display: grid;
  grid-gap: 15px;
  width: calc(100% - 60px);
  margin: 0 30px;
  height: auto;
  grid-template-columns: 180px 1fr;
  grid-template-rows: auto;

  .logo {
    justify-self: center;
    align-self: center;
    width: 180px;
    height: auto;


    img {
      display: block;
      width: 100%;
      height: auto;
      margin: 0 auto;
    }

  }

  .info_box {
    position: relative;
    display: grid;
    grid-gap: 0px;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    z-index: 20;

    .info {
      justify-self: start;
      align-self: end;
      position: relative;
      width: auto;
      height: auto;
      padding: 0 0 0 0;
      list-style: none;
      text-decoration: none;

      a {
        position: relative;
        width: auto;
        height: auto;
        font-family: 'Comfortaa-Regular';
        padding: 0;
        margin: 0 15px 0 0;
        font-family: 'Comfortaa-Regular';
        color: #464646;
        font-size: 1rem;
        line-height: 1.25rem;
        letter-spacing: 0;
        list-style: none;
        display: inline-block;
        text-decoration: none;
        text-align: center;
        transition: all .3s ease-in-out;

        &:hover {
          color: #98c01e;
          transition: all .3s ease-in-out;
        }
      }
    }

    .menu-top {
      justify-self: start;
      align-self: start;
      width: 100%;

      span {
        position: relative;
        width: 100%;
        height: auto;
        padding: 0;
        margin: 9px 0 0 0;
        list-style: none;
        display: inline-block;
        text-align: left;



        .active {
          color: #98c01e;
          transition: all .3s ease-in-out;
        }

        span {
          display: inline-block;
          position: relative;
          width: 15px;
          height: auto;
          padding: 0;
          margin: 0;
        }

        a {
          margin: 5px 0 0 10px;
          cursor: pointer;
          position: relative;
          width: auto;
          height: auto;
          font-family: 'Comfortaa-Regular';
          padding: 0;
          font-family: 'Comfortaa-Regular';
          color: #464646;
          font-size: 1rem;
          line-height: 1.25rem;
          letter-spacing: 0;
          list-style: none;
          display: inline-block;
          text-decoration: none;
          text-align: center;
          transition: all .3s ease-in-out;

          &:hover {
            color: #98c01e;
            transition: all .3s ease-in-out;
          }
        }
      }
    }
  }

}


@media (max-width: 1205px) {

  .nav .nav-content {

    .info_box {
      position: relative;
      display: grid;
      grid-gap: 0px;
      width: 100%;
      height: 100%;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      z-index: 20;

      .info {
        justify-self: start;
        align-self: end;
        position: relative;
        width: auto;
        height: auto;
        padding: 0 0 0 0;
        list-style: none;
        text-decoration: none;


        a {
          position: relative;
          width: auto;
          height: auto;
          font-family: 'Comfortaa-Regular';
          padding: 0;
          margin: 0 15px 0 0;
          font-family: 'Comfortaa-Regular';
          color: #464646;
          font-size: 1rem;
          line-height: 1.25rem;
          letter-spacing: 0;
          list-style: none;
          display: inline-block;
          text-decoration: none;
          text-align: center;
          transition: all .3s ease-in-out;

          &:hover {
            color: #98c01e;
            transition: all .3s ease-in-out;
          }
        }
      }

      .menu-top {
        justify-self: start;
        align-self: start;
        width: 100%;

        span {
          position: relative;
          width: 100%;
          height: auto;
          padding: 0;
          margin: 0 0 0 0;
          list-style: none;
          display: inline-block;
          text-align: left;



          .active {
            color: #98c01e;
            transition: all .3s ease-in-out;
          }

          span {
            display: inline-block;
            position: relative;
            width: 15px;
            height: auto;
            padding: 0;
            margin: 0;
          }

          a {

            cursor: pointer;
            position: relative;
            width: auto;
            height: auto;
            font-family: 'Comfortaa-Regular';
            padding: 0;
            font-family: 'Comfortaa-Regular';
            color: #464646;
            font-size: 1rem;
            line-height: 1.25rem;
            letter-spacing: 0;
            list-style: none;
            display: inline-block;
            text-decoration: none;
            text-align: center;
            transition: all .3s ease-in-out;

            &:hover {
              color: #98c01e;
              transition: all .3s ease-in-out;
            }
          }
        }
      }
    }

  }
}

@media (max-width: 1108px) {}

@media (max-width: 841px) {



  .nav .nav-content {
    position: relative;
    display: grid;
    grid-gap: 5px;
    width: calc(100% - 10px);
    margin: 0 5px;
    height: auto;
    grid-template-columns: 150px 1fr;
    grid-template-rows: auto;

    .logo {
      justify-self: center;
      align-self: center;
      width: 150px;
      height: auto;


      img {
        display: block;
        width: 100%;
        height: auto;
        margin: 0 auto;
      }

    }

    .info_box {
      position: relative;
      display: grid;
      grid-gap: 0px;
      width: 100%;
      height: 100%;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      z-index: 20;

      .info {
        justify-self: center;
        align-self: center;
        position: relative;
        width: auto;
        height: auto;
        padding: 0 42px 0 0;
        list-style: none;
        text-decoration: none;



        a {
          position: relative;
          width: auto;
          height: auto;
          font-family: 'Comfortaa-Regular';
          padding: 0;
          margin: 0 15px 0 0;
          font-family: 'Comfortaa-Regular';
          color: #464646;
          font-size: 1rem;
          line-height: 1.25rem;
          letter-spacing: 0;
          list-style: none;
          display: inline-block;
          text-decoration: none;
          text-align: center;
          transition: all .3s ease-in-out;

          &:hover {
            color: #98c01e;
            transition: all .3s ease-in-out;
          }
        }
      }

      .menu-top {
        overflow: hidden;
        justify-self: start;
        align-self: end;
        width: 100%;

        span {
          display: none !important;
          position: relative;
          width: 100%;
          height: auto;
          padding: 0;
          margin: 0;
          list-style: none;
          display: inline-block;
          text-align: left;

          li {
            cursor: pointer;
            position: relative;
            width: auto;
            height: auto;
            padding: 0;
            font-family: 'Comfortaa-Regular';
            color: #464646;
            font-size: 1rem;
            line-height: 1.35rem;
            margin: 0;
            list-style: none;
            display: inline-block;
            text-decoration: none;
            transition: all .3s ease-in-out;

            &:hover {
              color: #98c01e;
              transition: all .3s ease-in-out;
            }
          }

          .active {
            color: #98c01e;
            transition: all .3s ease-in-out;
          }

          span {
            display: inline-block;
            position: relative;
            width: 15px;
            height: auto;
            padding: 0;
            margin: 0;
          }

          a {
            position: relative;
            width: auto;
            height: auto;
            font-family: 'Comfortaa-Regular';
            padding: 0;

            font-family: 'Comfortaa-Regular';
            color: #464646;
            font-size: 1rem;
            line-height: 1.25rem;
            letter-spacing: 0;
            list-style: none;
            display: inline-block;
            text-decoration: none;
            text-align: center;
            transition: all .3s ease-in-out;

            &:hover {
              color: #98c01e;
              transition: all .3s ease-in-out;
            }
          }
        }
      }
    }

  }

}

@media (max-width: 590px) {
  .nav .nav-content {
    position: relative;
    display: grid;
    grid-gap: 5px;
    width: calc(100% - 10px);
    margin: 0 5px;
    height: 100%;
    grid-template-columns: 120px 1fr;
    grid-template-rows: auto;

    .logo {
      justify-self: start;
      align-self: center;
      width: 120px;
      height: auto;


      img {
        display: block;
        width: 100%;
        height: auto;
        margin: 0 auto;
      }

    }

    .info_box {
      justify-self: start;
      align-self: start;
      position: relative;
      display: grid;
      grid-gap: 0px;
      width: 100%;
      height: 100%;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 0;
      z-index: 20;

      .info {
        justify-self: start;
        align-self: center;
        position: relative;
        width: auto;
        height: auto;
        padding: 0 42px 0 0;
        list-style: none;
        text-decoration: none;





        a {
          position: relative;
          width: auto;
          height: auto;
          font-family: 'Comfortaa-Regular';
          padding: 0;
          margin: 0 15px 0 0;
          font-family: 'Comfortaa-Regular';
          color: #464646;
          font-size: 1rem;
          line-height: 1.25rem;
          letter-spacing: 0;
          list-style: none;
          display: inline-block;
          text-decoration: none;
          text-align: center;
          transition: all .3s ease-in-out;

          &:hover {
            color: #98c01e;
            transition: all .3s ease-in-out;
          }
        }
      }

      .menu-top {
        overflow: hidden;
        justify-self: start;
        align-self: end;
        width: 100%;

        ul {
          display: none !important;
          position: relative;
          width: 100%;
          height: auto;
          padding: 0;
          margin: 0;
          list-style: none;
          display: inline-block;
          text-align: left;

          li {
            cursor: pointer;
            position: relative;
            width: auto;
            height: auto;
            padding: 0;
            font-family: 'Comfortaa-Regular';
            color: #464646;
            font-size: 1rem;
            line-height: 1.35rem;
            margin: 0;
            list-style: none;
            display: inline-block;
            text-decoration: none;
            transition: all .3s ease-in-out;

            &:hover {
              color: #98c01e;
              transition: all .3s ease-in-out;
            }
          }

          .active {
            color: #98c01e;
            transition: all .3s ease-in-out;
          }

          span {
            display: inline-block;
            position: relative;
            width: 15px;
            height: auto;
            padding: 0;
            margin: 0;
          }

          a {
            position: relative;
            width: auto;
            height: auto;
            font-family: 'Comfortaa-Regular';
            padding: 0;
            font-family: 'Comfortaa-Regular';
            color: #464646;
            font-size: 1rem;
            line-height: 1.25rem;
            letter-spacing: 0;
            list-style: none;
            display: inline-block;
            text-decoration: none;
            text-align: center;
            transition: all .3s ease-in-out;

            &:hover {
              color: #98c01e;
              transition: all .3s ease-in-out;
            }
          }
        }
      }
    }

  }
}

@media (max-width: 391px) {
  .nav .nav-content {
    position: relative;
    display: grid;
    grid-gap: 5px;
    width: calc(100% - 10px);
    margin: 0 5px;
    height: 100%;
    grid-template-columns: 130px 1fr;
    grid-template-rows: auto;

    .logo {
      justify-self: start;
      align-self: center;
      width: 120px;
      height: auto;


      img {
        display: block;
        width: 100%;
        height: auto;
        margin: 0 auto;
      }

    }

    .info_box {
      justify-self: start;
      align-self: start;
      position: relative;
      display: grid;
      grid-gap: 0px;
      width: 100%;
      height: 100%;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 0;
      z-index: 20;

      .info {
        justify-self: start;
        align-self: center;
        position: relative;
        width: auto;
        height: auto;
        padding: 0 42px 0 0;
        list-style: none;
        text-decoration: none;

        .mail {
          display: none !important;
        }



        a {
          position: relative;
          width: auto;
          height: auto;
          font-family: 'Comfortaa-Regular';
          padding: 0;
          margin: 0 15px 0 0;
          font-family: 'Comfortaa-Regular';
          color: #464646;
          font-size: .8rem;
          line-height: 1.25rem;
          letter-spacing: 0;
          list-style: none;
          display: inline-block;
          text-decoration: none;
          text-align: center;
          transition: all .3s ease-in-out;

          &:hover {
            color: #98c01e;
            transition: all .3s ease-in-out;
          }
        }
      }

      .menu-top {
        overflow: hidden;
        justify-self: start;
        align-self: end;
        width: 100%;

        ul {
          display: none !important;
          position: relative;
          width: 100%;
          height: auto;
          padding: 0;
          margin: 0;
          list-style: none;
          display: inline-block;
          text-align: left;

          li {
            cursor: pointer;
            position: relative;
            width: auto;
            height: auto;
            padding: 0;
            font-family: 'Comfortaa-Regular';
            color: #464646;
            font-size: 1rem;
            line-height: 1.35rem;
            margin: 0;
            list-style: none;
            display: inline-block;
            text-decoration: none;
            transition: all .3s ease-in-out;

            &:hover {
              color: #98c01e;
              transition: all .3s ease-in-out;
            }
          }

          .active {
            color: #98c01e;
            transition: all .3s ease-in-out;
          }

          span {
            display: inline-block;
            position: relative;
            width: 15px;
            height: auto;
            padding: 0;
            margin: 0;
          }

          a {
            position: relative;
            width: auto;
            height: auto;
            font-family: 'Comfortaa-Regular';
            padding: 0;
            font-family: 'Comfortaa-Regular';
            color: #464646;
            font-size: 1rem;
            line-height: 1.25rem;
            letter-spacing: 0;
            list-style: none;
            display: inline-block;
            text-decoration: none;
            text-align: center;
            transition: all .3s ease-in-out;

            &:hover {
              color: #98c01e;
              transition: all .3s ease-in-out;
            }
          }
        }
      }
    }

  }

}


.sab-menu {
  display: inline-block;
  position: absolute !important;

  right: 0;
  width: calc(100% - 250px);
  height: 60px;
  background: #229038;
  position: relative;
  z-index: 4000;
  box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.10);

  ul {
    display: inline-block;
    width: calc(100%);
    height: 60px;
    list-style: none;


    li {
      display: inline-block;
      width: calc(100% / 4);
      height: 60px;

      a {
        cursor: pointer;
        position: relative;
        width: calc(100% - 20px);
        height: 49px;
        padding: 6px 10px;
        background: #229038;
        font-family: 'Comfortaa-Regular';
        color: #ffffff;
        font-size: 1rem;
        line-height: 3.25rem;
        letter-spacing: 0;
        display: inline-block;
        text-decoration: none;
        text-align: center;
        transition: all .3s ease-in-out;
        vertical-align: middle;

        &:hover {
          background: #fff;
          color: #98c01e;
          transition: all .3s ease-in-out;
        }




      }

      .active {
        background: #fff;
        color: #98c01e;
        transition: all .3s ease-in-out;
      }
    }
  }


}

@media (max-width: 1180px) {
  .sab-menu {
    display: inline-block;
    position: absolute !important;
    top: 65;
    right: 0;
    padding-left: 0;
    width: calc(100% - 200px);
    height: 50px;
    background: #229038;
    position: relative;
    z-index: 400;
    box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.10);

    ul {
      display: inline-block;
      width: 100%;
      height: 50px;
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        display: inline-block;
        width: calc(100% / 4);
        height: 50px;

        a {
          cursor: pointer;
          position: relative;
          width: calc(100% - 10px);
          height: 50px;
          font-family: 'Comfortaa-Regular';
          padding: 0;
          font-family: 'Comfortaa-Regular';
          color: #ffffff;
          padding: 5px 5px;
          font-size: .87rem;
          line-height: 1.25rem;
          letter-spacing: 0;
          list-style: none;
          display: inline-block;
          text-decoration: none;
          text-align: center;
          transition: all .3s ease-in-out;

          &:hover {
            background: #fff;
            color: #98c01e;
            transition: all .3s ease-in-out;
          }


        }

        .active {
          background: #fff;
          color: #98c01e;
          transition: all .3s ease-in-out;
        }
      }
    }


  }

}

@media (max-width: 623px) {

  .sab-menu {
    display: inline-block;
    position: absolute !important;
    top: 50px;
    right: 0;
    padding: 0;
    margin: 0;
    width: calc(100%);
    height: auto;
    background: #229038;
    position: relative;
    z-index: 100;
    box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.10);

    ul {
      position: relative;
      display: block;
      width: 100%;
      height: auto;
      list-style: none;
      padding-left: 0;
      margin: 0;
      padding: 0;
      text-align: center;

      li {
        position: relative;
        list-style: none;
        display: inline-block;
        width: calc(100% / 4);
        height: auto;
        margin: 0;
        padding: 0;
        text-align: center;

        a {
          cursor: pointer;
          position: relative;
          width: calc(100% - 20px);
          height: calc(50px - 3px);
          font-family: 'Comfortaa-Regular';
          color: #ffffff;
          padding: 3px 10px 0 10px;
          font-size: .87rem;
          line-height: 1.15rem;
          letter-spacing: 0;
          list-style: none;
          display: inline-block;
          text-decoration: none;
          text-align: center;
          transition: all .3s ease-in-out;

          &:hover {
            background: #fff;
            color: #98c01e;
            transition: all .3s ease-in-out;
          }


        }

        // .active {
        //   background: #fff;
        //   color: #98c01e;
        //   transition: all .3s ease-in-out;
        // }
      }
    }


  }
}

@media (max-width: 482px) {
  .sab-menu {
    display: inline-block;
    position: absolute !important;
    top: 50px;
    right: 0;
    padding: 0;
    margin: 0;
    width: calc(100%);
    height: 40px;
    background: #229038;
    position: relative;
    z-index: 100;
    box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.10);

    ul {
      display: inline-block;
      width: 100%;
      height: 40px;
      list-style: none;
      padding: 0;
      margin: 0;
      text-align: left;

      li {
        display: inline-block;
        width: calc(100% / 4);
        height: 40px;
        padding: 0;
        margin: 0;
        text-align: center;

        a {
          cursor: pointer;
          position: relative;
          width: calc(100% - 4px);
          height: 30px;
          font-family: 'Roboto-Regular';
          color: #ffffff;
          padding: 5px 2px;
          font-size: .7rem;
          line-height: .95rem;
          vertical-align: middle;
          letter-spacing: 0;
          list-style: none;
          display: inline-block;
          text-decoration: none;
          text-align: center;
          transition: all .3s ease-in-out;

          &:hover {
            background: #fff;
            color: #98c01e;
            transition: all .3s ease-in-out;
          }


        }

        // .active {
        //   background: #fff;
        //   color: #98c01e;
        //   transition: all .3s ease-in-out;
        // }
      }
    }


  }
}



.section {
  position: relative;
  display: block;
  width: 100vw;
  height: auto;

  .section-content {
    position: relative;
    display: block;
    width: 100vw;
    height: auto;



    .headh1 {
      position: absolute !important;
      top: 0px;
      left: 0;
      width: 250px;
      height: 60px;
      background: #98c01e;
      display: grid;
      grid-gap: 15px;
      padding: 0 10px;
      height: auto;
      grid-template-columns: calc(250px - 20px);
      grid-template-rows: 60px;
      z-index: 200;

      h1 {
        color: #fff;
        font-size: 1.1rem;
        line-height: 2.8rem;
        text-align: right;
        text-transform: uppercase;
        justify-self: center;
        align-self: center;
      }
    }
  }




}

@media (max-width: 760px) {
  .section {
    position: relative;
    display: block;
    width: 100vw;
    height: auto;

    .section-content {
      position: relative;
      display: block;
      width: 100vw;
      height: auto;

      .headh1 {
        position: absolute !important;
        top: 0px;
        left: 0;
        width: 100%;
        height: 60px;
        background: #98c01e;
        display: grid;
        grid-gap: 15px;
        padding: 0 10px;
        height: auto;
        grid-template-columns: 1fr;
        grid-template-rows: 50px;
        z-index: 200;

        h1 {
          width: calc(100% - 20px);
          color: #fff;
          font-size: 1.1rem;
          line-height: 2.8rem;
          padding-left: 20px;
          text-align: left;
          text-transform: uppercase;
          justify-self: center;
          align-self: center;
        }
      }
    }
  }
}



/// honme
/// 
.enter {
  position: relative;
  display: block;
  width: 100vw;
  top: 90px;
  left: 0;
  height: calc(100vh);
  overflow: hidden;
  opacity: 1;
  background: #fff !important;
  box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.10);

  .mob-enter {
    display: none !important;
  }

  .rect-box {
    width: 100%;
    height: 100%;
    display: grid;
    grid-gap: 0;
    grid-template-columns: auto;
    grid-template-rows: auto;
    background: #fff url('./img/index-bg.webp') center center / cover;
    z-index: 10;

    .styleimport {
      position: absolute;
      display: inline-block;
      overflow: hidden;
      width: 100% !important;
      transform: translate(-50%, -50%);
      left: 50%;
      height: 40px;
      top: calc(100% - 17px);


    }

    .rect {
      position: relative;
      align-self: center;
      justify-self: center;
      box-sizing: border-box;
      background: transparent;
      transform: rotate(45deg);

      .intro-box {
        position: absolute;
        display: block;
        width: inherit;
        height: inherit;
        overflow: hidden;
        box-sizing: border-box;
        z-index: 100;

        .intro {
          position: absolute;
          top: 50%;
          left: 50%;
          background-image: url('./img/intro.webp');
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 100%;
          transform: translate(-50%, -50%) rotate(-45deg);
          box-sizing: border-box;
          z-index: 100;

        }


      }

      .seed {
        box-sizing: border-box;
        position: absolute;
        display: block;
        width: inherit;
        height: inherit;
        z-index: 10;


      }

      .link-prod {
        position: absolute;
        display: inline-block;
        top: calc(100% - 20px);
        left: 50%;
        background-color: #229038;
        transform: translate(-50%, -50%);
        cursor: pointer;
        text-align: center;
        display: inline-block;
        width: 380px;
        padding: 10px 20px 10px 20px;
        position: relative;
        height: auto;
        font-family: 'Comfortaa-Regular';
        color: rgb(255, 255, 255);
        font-size: .76em;
        line-height: 1.1em;
        text-decoration: none;
        opacity: .9;
        transition: all 0.3s ease-in-out;


        &:hover {
          background-color: rgba(152, 192, 30, .9);
          color: rgb(255, 255, 255);

          transition: all 0.3s ease-in-out;
        }
      }

      .corobki {
        cursor: pointer;
        overflow: hidden;
        background: url('./img/cor1.webp') center center / cover;
      }

      .corobki2 {
        // display: none;
        overflow: hidden;
        background: url('./img/rec2.webp') center center / cover;
      }

      .poketi {
        cursor: pointer;
        background: url('./img/pak2.webp') center center / cover;
      }

      .rek {
        cursor: pointer;
        background: url('./img/cor1.webp') center center / cover;
      }

      .reklama {
        display: block !important;
        background: url('./img/rec2.webp') center center / cover;
      }

      .galery {
        cursor: pointer;
        overflow: hidden;
        background: url('./img/plaski.webp') center center / cover;


      }

      .slaid1 {
        width: 100%;
        height: 100%;
        background: url('./img/perec.webp') center center / cover;
      }

      .slaid2 {
        width: 100%;
        height: 100%;
        background: url('./img/comos.webp') center center / cover;
      }

    }
  }

}




/// 
/// honme
/// 
/// Faq
.Faq {
  position: relative;
  width: 100vw;
  height: auto;
  opacity: 1;
  // background: #fff url('./img/comp.png') center top / cover;
  background-image: url('./img/faq.webp');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% auto;
  background-color: #fff;
  z-index: 1;
  box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.10);

  .faq-box {
    width: 100%;
    height: auto;
    background: transparent;
    padding: 90px 0;
    text-align: center;

    .faq-box-question {
      display: inline-block;
      position: relative;
      width: calc(100% - 110px);
      max-width: 900px;
      padding: 0 50px 0 40px;
      height: 80vh;
      background-color: #ffffff;
      border-radius: 5px;
      overflow-y: scroll;
      box-shadow: 0 2px 9px 0 rgba(86, 112, 7, 0.15);

      scrollbar-width: thin;

      .question {
        position: relative;
        display: inline-block;
        width: 100%;
        height: auto;
        padding: 20px 0 5px 0;
        text-align: left;

        button {
          width: 100%;
          position: relative;
          display: inline-block;
          border: none;
          border-bottom: 1px solid #E1E1E1;
          margin: 0;
          padding: 0 60px 10px 0;
          margin-bottom: 10px;
          cursor: pointer;
          text-align: left;
          background: transparent;
          font-family: 'Roboto-Regular';
          font-size: 1.1rem;
          line-height: 1.4rem;
          letter-spacing: .002rem;
          color: #484848;

          &:after {
            position: absolute;
            display: block;
            left: 100%;
            top: 0%;
            transform: translate(-50%, -50%);
            width: 2rem;
            height: 2rem;
            font-size: 2rem;
            line-height: 2rem;
            content: "+";
            background: #cbcbcb;
            color: #fff;
            text-align: center;
            border-radius: 100%;
            font-family: 'Roboto-Thin';
            z-index: 100;
            transition: all 0.3s ease-in-out;


          }

          &:hover {

            &:after {
              background: #4c4c4c;

              transition: all 0.3s ease-in-out;

            }
          }



        }

        .active {
          font-family: 'Roboto-Bold';

          &:after {
            left: 100%;
            top: 45%;

            background: #4c4c4c;
            transform: rotate(-45deg) translate(-50%, -50%);
            transition: all 0.3s ease-in-out;

          }
        }
      }

      .answer {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 0;
        padding: 0 0;
        text-align: left;
        overflow: hidden;



      }

      .answer-active {
        position: relative;
        display: inline-block;
        width: 100%;
        height: auto;
        padding: 10px 0 30px 0;
        text-align: left;
        overflow: hidden;

      }


    }

  }

  h2 {
    font-family: 'Comfortaa-Bold';
    font-size: 2rem;
    line-height: 2.4rem;
    padding: 10px 5px;
    color: #000000;
  }

  p {
    font-family: 'Roboto-Regular';
    font-size: 1rem;
    line-height: 1.2rem;
    letter-spacing: .002rem;
    color: #484848;
  }

  b {
    font-family: 'Roboto-Bold';
    font-size: 1rem;
    line-height: 1.2rem;
    letter-spacing: .002rem;
    color: #484848;
  }

  .table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    text-align: center;

    tr {
      border: 1px solid #484848;
    }

    th {
      font-family: 'Roboto-Regular';
      font-size: 1rem;
      line-height: 1.2rem;
      letter-spacing: .002rem;
      color: #484848;
      padding: 15px 5px;
      border: 1px solid #484848;
    }

    td {
      font-family: 'Roboto-Regular';
      font-size: 1rem;
      line-height: 1.2rem;
      letter-spacing: .002rem;
      color: #484848;
      padding: 15px 5px;

    }
  }

  a {
    cursor: pointer;
    text-align: center;
    display: inline-block;
    width: auto;
    position: relative;
    height: auto;
    font-family: 'Comfortaa-Regular';
    color: #464646;
    font-size: .87em;
    line-height: 1.1em;
    text-decoration: none;
    opacity: 1;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: #98C01E;
      transition: all 0.3s ease-in-out;
    }


  }
}




////
/// Faq

//// contacts
/// 
/// 


.contacts {
  position: relative;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  background: #fff;


  z-index: 1;
  box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.10);

  .headh1 {
    position: absolute;
    top: 100px;
    left: 0;
    width: 250px;
    height: 60px;
    background: #98c01e;
    position: relative;
    display: grid;
    grid-gap: 15px;
    padding: 0 10px;
    height: auto;
    grid-template-columns: calc(250px - 20px);
    grid-template-rows: 60px;
    z-index: 200;

    h1 {
      color: #fff;
      font-size: 1.1rem;
      line-height: 2.8rem;
      text-align: right;
      text-transform: uppercase;
      justify-self: center;
      align-self: center;
    }
  }



  .contacts-box {
    position: absolute;
    width: calc(300px - 30px);
    height: auto;
    transform: translateY(-50%);
    padding: 30px 15px;
    left: 0;
    top: calc(100% - 250px);
    background: #fff;
    box-shadow: 0 2px 9px 0 rgba(86, 112, 7, 0.15);
    border-radius: 3px;
    transition: all 0.3s ease-in-out;

    .contacts-descr {
      width: calc(100% - 40px);
      padding: 0 20px;
      align-self: start;
      justify-self: center;
      text-align: center;
      display: grid;

      grid-gap: 20px;
      grid-template-columns: 1fr;
      grid-template-rows: auto;


      .adres {
        display: grid;
        grid-gap: 30px;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        text-align: left;
      }

      .contatc {
        display: grid;
        grid-gap: 5px;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        text-align: left;

        .for-mob {
          display: none;
        }


        a {
          text-align: left;
        }
      }

      button {
        width: 100%;
        position: relative;
        display: inline-block;
        border: none;
        margin: 0;
        left: calc(100% - 20px);
        top: 0;
        cursor: pointer;
        text-align: right;
        background: transparent;
        width: 2rem;
        height: 2rem;

        &:after {
          position: absolute;
          display: block;
          left: calc(50% - 1rem);
          top: calc(50% - 1rem);
          transform: rotate(0);
          transform-origin: center;
          width: 2rem;
          height: 2rem;
          font-size: 2rem;
          line-height: 2rem;
          content: "+";
          background: #cbcbcb;
          color: #fff;
          text-align: center;
          border-radius: 100%;
          font-family: 'Roboto-Thin';
          z-index: 100;
          transition: all 0.3s ease-in-out;
        }

        &:hover {

          &:after {
            background: #4c4c4c;
            transition: all 0.3s ease-in-out;

          }
        }



      }

      .active {
        font-family: 'Roboto-Bold';

        &:after {
          background: #4c4c4c;
          transform: rotate(-45deg);
          transform-origin: center;
          transition: all 0.3s ease-in-out;

        }
      }



    }


  }

  .cont-active {
    transition: all 0.3s ease-in-out;
    left: -220px;
    height: auto;
  }

  h2 {
    font-family: 'Comfortaa-Bold';
    font-size: 2rem;
    line-height: 2.4rem;
    padding: 10px 5px;
    color: #000000;
  }

  p {
    font-family: 'Roboto-Regular';
    font-size: 1rem;
    line-height: 1.2rem;
    letter-spacing: .002rem;
    color: #484848;
  }

  b {
    font-family: 'Roboto-Bold';
    font-size: 1rem;
    line-height: 1.2rem;
    letter-spacing: .002rem;
    color: #484848;
  }

  .table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    text-align: center;

    tr {
      border: 1px solid #484848;
    }

    th {
      font-family: 'Roboto-Regular';
      font-size: 1rem;
      line-height: 1.2rem;
      letter-spacing: .002rem;
      color: #484848;
      padding: 15px 5px;
      border: 1px solid #484848;
    }

    td {
      font-family: 'Roboto-Regular';
      font-size: 1rem;
      line-height: 1.2rem;
      letter-spacing: .002rem;
      color: #484848;
      padding: 15px 5px;

    }
  }

  a {
    cursor: pointer;
    text-align: center;
    display: inline-block;
    width: auto;
    position: relative;
    height: auto;
    font-family: 'Comfortaa-Regular';
    color: #464646;
    font-size: .87em;
    line-height: 1.1em;
    text-decoration: none;
    opacity: 1;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: #98C01E;
      transition: all 0.3s ease-in-out;
    }


  }
}




//// comp
/// 
.comp {
  position: relative;
  width: 100vw;
  height: calc(100vh - 90px);
  opacity: 1;
  background-color: #fff;
  background-image: url('./img/comp.webp');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  z-index: 1;
  box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.10);

  .comp-box {
    position: relative;
    display: grid;
    width: 100%;
    grid-gap: 30px;
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    .comp-box-info {
      align-self: center;
      justify-self: center;
      width: calc(100% - 30px);
      max-width: 900px;
      padding: 45px 15px;
      grid-gap: 30px;
      height: auto;
      background-color: rgba(#fff, .95);
      border-radius: 5px;

      -webkit-backdrop-filter: blur(3px);
      backdrop-filter: blur(3px);

      .comp-info {
        align-self: center;
        justify-self: center;
        text-align: center;
        position: relative;
        width: calc(100% - 30px);
        padding: 15px;

        height: auto;


        h2 {
          font-family: 'Comfortaa-Bold';
          font-size: 2rem;
          line-height: 2.45rem;
          padding: 0 5px 15px 5px;
          color: #000000;
          transition: all .3s ease-in-out;
        }

        p {
          font-family: 'Roboto-Regular';
          font-size: 1.1rem;
          line-height: 1.4rem;
          letter-spacing: .002rem;
          color: #484848;
        }

        b {
          font-family: 'Roboto-Bold';
          font-size: 1rem;
          line-height: 1.2rem;
          letter-spacing: .002rem;
          color: #484848;
        }

        .table {
          width: 100%;
          max-width: 100%;
          margin-bottom: 1rem;
          background-color: transparent;
          text-align: center;

          tr {
            border: 1px solid #484848;
          }

          th {
            font-family: 'Roboto-Regular';
            font-size: 1rem;
            line-height: 1.2rem;
            letter-spacing: .002rem;
            color: #484848;
            padding: 15px 5px;
            border: 1px solid #484848;
          }

          td {
            font-family: 'Roboto-Regular';
            font-size: 1rem;
            line-height: 1.2rem;
            letter-spacing: .002rem;
            color: #484848;
            padding: 15px 5px;

          }
        }

        a {
          cursor: pointer;
          text-align: center;
          display: inline-block;
          width: auto;
          position: relative;
          height: auto;
          font-family: 'Comfortaa-Regular';
          color: #464646;
          font-size: 1em;
          line-height: 1.25em;
          text-decoration: none;
          opacity: 1;
          transition: all 0.3s ease-in-out;

          &:hover {
            color: #98C01E;
            transition: all 0.3s ease-in-out;
          }


        }
      }






    }
  }
}


//// box-prodact
/// 
.box-prodact {
  position: relative;
  margin-top: 90px;
  width: 100vw;
  height: calc(101vh - 89px);
  opacity: 1;
  background-color: #fff;
  background-image: url('./img/comp.webp');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  z-index: 1;
  box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.10);
  overflow: hidden !important;

}



/// 
//// box-prodact
/// 
.Bags {
  position: relative;
  display: block;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background: #fff url('./img/0002.webp') center center / cover !important;


}

.Cardboard {
  position: relative;
  display: block;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background: #fff url('./img/0002.webp') center center / cover !important;


}

.Advertisement {
  position: relative;
  display: block;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background: #fff url('./img/0002.webp') center center / cover !important;

}

.Plastic {
  position: relative;
  display: block;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background: #fff url('./img/0002.webp') center center / cover !important;
}

.page {
  position: relative;
  display: block;
  width: 100vw;
  height: 100vh;
  overflow: hidden;


  .slide-box {
    position: absolute;
    display: inline-block;
    width: 1px;
    height: 1px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index: 1;

    .smol1 {
      position: absolute;
      width: 250px;
      height: 250px;
      top: 50%;
      left: calc(50% - 360px);
      filter: blur(2px);
      transform: translate(-50%, -50%);
      overflow: hidden;
      transition: all .5s ease-in-out;

      img {
        position: relative;
        display: inline-block;
        width: calc(100% - 10px);
        height: auto;

        z-index: 20;
      }

      .description {
        position: absolute;

        top: 100%;
        opacity: 0;
      }




    }

    .smol2 {
      position: absolute;
      width: 200px;
      height: 200px;
      top: 50%;
      left: calc(50% - 600px);
      transform: translate(-50%, -50%);
      filter: blur(5px);
      overflow: hidden;
      transition: all .3s ease-in-out;

      .description {
        position: absolute;

        top: 100%;
        opacity: 0;
      }

      img {
        position: relative;
        display: inline-block;
        width: calc(100% - 10px);
        height: auto;
        z-index: 20;
      }


    }

    .big {

      position: absolute;
      display: block;
      width: 750px;
      height: 750px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: transparent;

      z-index: 10;
      overflow: hidden;
      transition: all .6s ease-in-out;

      img {
        position: relative;
        display: inline-block;
        width: calc(100% - 20px);
        height: auto;
        z-index: 20;
        transition: all .3s ease-in-out
      }

      .description {
        transition: all .8s ease-in-out
      }
    }

    .smol3 {
      position: absolute;
      width: 250px;
      height: 250px;
      top: 50%;
      left: calc(50% + 360px);
      transform: translate(-50%, -50%);
      filter: blur(2px);

      .description {
        opacity: 0;

      }

      transition: all .3s ease-in-out;
      overflow: hidden;



      img {
        position: relative;
        display: inline-block;
        width: calc(100% - 10px);
        height: auto;
        z-index: 20;
      }


    }

    .smol4 {
      position: absolute;
      width: 200px;
      height: 200px;
      top: 50%;
      left: calc(50% + 600px);
      transform: translate(-50%, -50%);
      transition: all .5s ease-in-out;
      overflow: hidden;
      filter: blur(5px);

      .description {
        opacity: 0;

      }

      img {
        position: relative;
        display: inline-block;
        width: calc(100% - 10px);
        height: auto;

        z-index: 20;
      }
    }

    .no-smol {
      position: absolute;
      width: 200px;
      height: 200px;
      top: 50%;
      left: 200%;
      transform: translate(-50%, -50%);
      transition: all .5s ease-in-out;
      overflow: hidden;
      filter: blur(5px);
      opacity: 0;

      .description {
        opacity: 0;

      }

      img {
        position: relative;
        display: inline-block;
        width: calc(100% - 10px);
        height: auto;

        z-index: 20;
      }
    }

    .description {
      opacity: .9;
      position: relative;
      display: inline-block;
      width: calc(100% - 30px);
      max-width: 320px;
      padding: 15px;
      top: -20%;
      left: 40%;
      transform: translate(-50%, -50%);
      height: auto;
      background-color: #fff;
      border-radius: 3px;

      z-index: 50;
      box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.10);

      .btn {
        cursor: pointer;
        position: relative;
        display: inline-block;
        width: 120px;
        height: 40px;
        margin-top: 10px;
        border-radius: 3px;
        border: 0;
        background-color: #229038;
        color: #fff;
        font-size: .87rem;
        line-height: 1.1rem;
        z-index: 50;
        box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.10);
        transition: all .3s ease-in-out;

        &:hover {
          color: rgba(#fff, .5);
          box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.40);
          background-position: 60% 50%;
          transition: all .3s ease-in-out;

        }
      }

      h3 {
        font-family: 'Comfortaa-Regular';
        font-size: 1.6rem;
        line-height: 1.8rem;
        color: #229038;
      }

      p {
        color: #333;
        font-family: "Roboto-Regular";
        font-size: .9rem;
        line-height: 1.1rem;
        letter-spacing: 0.01rem;
        padding: 3px 5px;
      }
    }
  }



  .left {
    cursor: pointer;
    position: absolute;
    display: inline-block;
    width: 60px;
    height: 60px;
    top: 50%;
    left: 100px;
    border-radius: 100%;
    background-image: url('./img/left.svg');
    background-repeat: no-repeat;
    background-position: 55% 50%;
    background-size: 20px;
    transform: translate(-50%, -50%);

    border: 0;
    background-color: #fff;
    z-index: 50;
    box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.10);
    transition: all .3s ease-in-out;


    &:hover {

      animation: none;
      box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.40);
      background-position: 60% 50%;
      transition: all .3s ease-in-out;

    }
  }

  .right {
    cursor: pointer;
    position: absolute;
    display: inline-block;
    width: 60px;
    height: 240px;
    top: 50%;
    left: calc(100% - 40px);
    transform: translate(-50%, -50%);
    border: 0;
    background: transparent;
    z-index: 20;

    .romb {
      position: absolute;
      display: inline-block;
      width: 50px;
      height: 240px;
      font-size: .87rem;
      line-height: 3rem;
      letter-spacing: .05rem;
      color: #fff;
      border-radius: 3px;
      top: 0;
      left: 20px;
      background-color: rgba(#229038, .7);
      writing-mode: vertical-rl;
      border: .005rem solid #fff;
      transition: all .3s ease-in-out;
    }

    &:hover {
      .romb {
        border: .005rem solid rgba(#fff, .7);
        letter-spacing: .0rem;
        transition: all .3s ease-in-out;
      }
    }
  }

  .bg-text {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-radius: 100%;
    left: 50%;
    top: 50%;
    z-index: 1000;
    transform: translate(-50%, -50%);
    background-color: rgba(#229038, .7);
    transition: all 0s ease-in-out;
  }

  .bg-text-active {
    position: absolute;
    display: block;
    width: 200vw;
    height: 200vw;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0%;
    top: 0;
    left: 50%;
    top: 50%;
    z-index: 3000;
    background-color: rgba(#229038, .7);
    transition: all 0s ease-in-out;
  }

  .text-desription-box {
    position: absolute;
    border-radius: 3px;
    display: block;
    width: calc(100vw - 60px);
    height: auto;
    left: -200%;
    top: calc(50% - 60px);
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 4000;
    opacity: 1;
    box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.10);


    .text-desription {
      display: block;
      margin: 40px auto;
      width: calc(100% - 40px);
      padding: 20px;
      max-width: 900px;

      h3 {
        font-family: 'Comfortaa-Regular';
        font-size: 2rem;
        line-height: 3rem;
        color: #229038;
      }

      p {
        color: #333;
        font-family: "Roboto-Regular";
        font-size: 1rem;
        line-height: 1.3rem;
        letter-spacing: 0.01rem;
        padding: 3px 5px;
      }
    }

    .close {
      cursor: pointer;
      position: absolute;
      display: inline-block;
      width: 70px;
      height: 70px;
      top: 10px;
      right: 10px;
      background-color: transparent;
      background-image: url('./img/close-b.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      border: .005rem solid #fff;
      z-index: 20;

      transition: all .3s ease-in-out;

      &:hover {
        transition: all .3s ease-in-out;
        background-size: 90%;
      }
    }
  }

  .text-desription-box-active {
    position: absolute;
    border-radius: 3px;
    display: block;
    width: calc(100vw - 60px);
    height: auto;
    left: 50%;
    top: calc(50% - 60px);
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 4000;
    opacity: 1;
    box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.10);
    animation: textmove .6s ease-in-out;




    .close {
      cursor: pointer;
      position: absolute;
      display: inline-block;
      width: 70px;
      height: 70px;
      top: 10px;
      right: 10px;
      background-color: transparent;
      background-image: url('./img/close-b.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      border: .005rem solid #fff;
      z-index: 20;

      transition: all .3s ease-in-out;

      &:hover {
        transition: all .3s ease-in-out;
        background-size: 90%;
      }
    }

    .text-desription {
      display: block;
      margin: 0 auto;
      width: calc(100% - 40px);
      // height: calc(100vh - 200px);
      height: auto;
      padding: 20px;
      max-width: 900px;
      overflow: hidden;

      .text-desription-scroll {
        position: relative;
        display: block;
        margin: 0 auto;
        width: calc(100% - 10px);
        padding: 5px 5px 45px 5px;
      }

      h3 {
        font-family: 'Comfortaa-Regular';
        font-size: 2rem;
        line-height: 3rem;
        color: #229038;
      }

      p {
        color: #333;
        font-family: "Roboto-Regular";
        font-size: 1rem;
        line-height: 1.3rem;
        letter-spacing: 0.01rem;
        padding: 3px 5px;
      }


    }
  }
}

//////
@media (max-width: 1408px) {
  .enter {
    position: relative;
    display: block;
    width: 100vw;
    top: 65px;
    left: 0;
    height: calc(100vh);
    overflow: hidden;
    opacity: 1;
    background: #fff !important;
    box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.10);

    .mob-enter {
      display: none !important;
    }

    .rect-box {
      width: 100%;
      height: 100%;
      display: grid;
      grid-gap: 0;
      grid-template-columns: auto;
      grid-template-rows: auto;
      background: #fff url('./img/index-bg.webp') center center / cover;
      z-index: 10;

      .styleimport {
        position: absolute;
        display: inline-block;
        overflow: hidden;
        width: 100% !important;
        transform: translate(-50%, -50%);
        left: 50%;
        height: 40px;
        top: calc(100% - 17px);


      }

      .rect {
        position: relative;
        align-self: center;
        justify-self: center;
        box-sizing: border-box;
        background: transparent;
        transform: rotate(45deg);

        .intro-box {
          position: absolute;
          display: block;
          width: inherit;
          height: inherit;
          overflow: hidden;
          box-sizing: border-box;
          z-index: 100;

          .intro {
            position: absolute;
            top: 50%;
            left: 50%;
            background-image: url('./img/intro.webp');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100%;
            transform: translate(-50%, -50%) rotate(-45deg);
            box-sizing: border-box;
            z-index: 100;

          }


        }

        .seed {
          box-sizing: border-box;
          position: absolute;
          display: block;
          width: inherit;
          height: inherit;
          z-index: 10;


        }

        .link-prod {
          position: absolute;
          display: inline-block;
          top: calc(100% - 20px);
          left: 50%;
          background-color: #229038;
          transform: translate(-50%, -50%);
          cursor: pointer;
          text-align: center;
          display: inline-block;
          width: 380px;
          padding: 10px 20px 10px 20px;
          position: relative;
          height: auto;
          font-family: 'Comfortaa-Regular';
          color: rgb(255, 255, 255);
          font-size: .76em;
          line-height: 1.1em;
          text-decoration: none;
          opacity: .9;
          transition: all 0.3s ease-in-out;


          &:hover {
            background-color: rgba(152, 192, 30, .9);
            color: rgb(255, 255, 255);

            transition: all 0.3s ease-in-out;
          }
        }

        .corobki {
          cursor: pointer;
          overflow: hidden;
          background: url('./img/cor1.webp') center center / cover;
        }

        .corobki2 {
          // display: none;
          overflow: hidden;
          background: url('./img/rec2.webp') center center / cover;
        }

        .poketi {
          cursor: pointer;
          background: url('./img/pak2.webp') center center / cover;
        }

        .rek {
          cursor: pointer;
          background: url('./img/cor1.webp') center center / cover;
        }

        .reklama {
          display: block !important;
          background: url('./img/rec2.webp') center center / cover;
        }

        .galery {
          cursor: pointer;
          overflow: hidden;
          background: url('./img/plaski.webp') center center / cover;


        }

        .slaid1 {
          width: 100%;
          height: 100%;
          background: url('./img/perec.webp') center center / cover;
        }

        .slaid2 {
          width: 100%;
          height: 100%;
          background: url('./img/comos.webp') center center / cover;
        }

      }
    }

  }
}

@media (max-width: 490px) {
  .enter {
    position: relative;
    display: block;
    width: 100vw;
    opacity: 1;
    background: #fff !important;
    box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.10);

    .mob-enter {
      display: block !important;
      position: relative;
      width: 100vw;
      top: 0;
      left: 0;
      height: 100%;
      background: url('../src/img/fon.webp') center center / cover;

      opacity: 1;

      .mob-enter-box {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        width: 160px;
        height: 160px;
      }

      .Package-mob-enter {
        position: absolute;
        top: -170px;
        left: -60px;
        display: inline-block;
        width: 160px;
        height: 160px;
        transform: rotate(45deg);
        background-image: url('./img/pak2.webp');
        background-repeat: no-repeat;
        background-size: 150px 150px;
        background-position: center;
        border: 1px solid #fff;

        .link-prod {
          cursor: pointer;
          position: absolute;
          display: inline-block;
          top: 70%;
          left: 40%;
          width: 50%;
          height: 50%;
          background: rgb(151, 191, 30);
          background: -moz-linear-gradient(135deg, rgba(151, 191, 30, 1) 0%, rgba(240, 240, 240, 1) 100%);
          background: -webkit-linear-gradient(135deg, rgba(151, 191, 30, 1) 0%, rgba(240, 240, 240, 1) 100%);
          background: linear-gradient(135deg, rgba(151, 191, 30, 1) 0%, rgba(240, 240, 240, 1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#97bf1e", endColorstr="#f0f0f0", GradientType=1);
          box-shadow: 0 2px 10px 0 rgba(20, 50, 70, 0.30);

          span {
            position: absolute;
            display: inline-block;
            top: 15px;
            left: 15px;
            width: 60px;
            height: 60px;
            transform: rotate(-45deg);
            text-align: center;
            font-family: 'Roboto-Regular';
            color: #464646;
            font-size: .8rem;
            line-height: .85rem;
            letter-spacing: 0;
          }


        }

      }

      .Cardboard-mob-enter {
        position: absolute;
        display: inline-block;
        top: -35px;
        left: 70px;
        width: 160px;
        height: 160px;
        transform: rotate(45deg);
        background-image: url('./img/cor1.webp');
        background-repeat: no-repeat;
        background-size: 150px 150px;
        background-position: center;
        border: 1px solid #fff;

        .link-prod {
          cursor: pointer;
          position: absolute;
          display: inline-block;
          top: -30%;
          left: -30%;
          width: 50%;
          height: 50%;
          background: rgb(151, 191, 30);
          background: -moz-linear-gradient(135deg, rgba(151, 191, 30, 1) 0%, rgba(240, 240, 240, 1) 100%);
          background: -webkit-linear-gradient(135deg, rgba(151, 191, 30, 1) 0%, rgba(240, 240, 240, 1) 100%);
          background: linear-gradient(135deg, rgba(151, 191, 30, 1) 0%, rgba(240, 240, 240, 1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#97bf1e", endColorstr="#f0f0f0", GradientType=1);
          box-shadow: 0 2px 10px 0 rgba(20, 50, 70, 0.30);

          span {
            position: absolute;
            display: inline-block;
            top: 17px;
            left: 17px;
            width: 60px;
            height: 60px;
            transform: rotate(-45deg);
            text-align: center;
            font-family: 'Roboto-Regular';
            color: #464646;
            font-size: .8rem;
            line-height: .85rem;
            letter-spacing: 0;
          }

        }
      }

      .Promotional-mob-enter {
        position: absolute;
        display: inline-block;
        top: 100px;
        left: -60px;
        width: 160px;
        height: 160px;
        transform: rotate(45deg);
        background-image: url('./img/rec2.webp');
        background-repeat: no-repeat;
        background-size: 150px 150px;
        background-position: center;
        border: 1px solid #fff;

        .link-prod {
          cursor: pointer;
          position: absolute;
          display: inline-block;
          top: 75%;
          left: 35%;
          width: 50%;
          height: 50%;
          background: rgb(151, 191, 30);
          background: -moz-linear-gradient(135deg, rgba(151, 191, 30, 1) 0%, rgba(240, 240, 240, 1) 100%);
          background: -webkit-linear-gradient(135deg, rgba(151, 191, 30, 1) 0%, rgba(240, 240, 240, 1) 100%);
          background: linear-gradient(135deg, rgba(151, 191, 30, 1) 0%, rgba(240, 240, 240, 1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#97bf1e", endColorstr="#f0f0f0", GradientType=1);
          box-shadow: 0 2px 10px 0 rgba(20, 50, 70, 0.30);

          span {
            position: absolute;
            display: inline-block;
            top: 21px;
            left: 19px;
            width: 60px;
            height: 60px;
            transform: rotate(-45deg);
            text-align: center;
            font-family: 'Roboto-Regular';
            color: #464646;
            font-size: .8rem;
            line-height: .85rem;
            letter-spacing: 0;
          }

        }
      }

      .Plastic-mob-enter {
        position: absolute;
        display: inline-block;
        top: 240px;
        left: 70px;
        width: 160px;
        height: 160px;
        transform: rotate(45deg);
        background-image: url('./img/plaski.webp');
        background-repeat: no-repeat;
        background-size: 150px 150px;
        background-position: center;
        border: 1px solid #fff;

        .link-prod {
          cursor: pointer;
          position: absolute;
          display: inline-block;
          top: -30%;
          left: -30%;
          width: 50%;
          height: 50%;
          background: rgb(151, 191, 30);
          background: -moz-linear-gradient(135deg, rgba(151, 191, 30, 1) 0%, rgba(240, 240, 240, 1) 100%);
          background: -webkit-linear-gradient(135deg, rgba(151, 191, 30, 1) 0%, rgba(240, 240, 240, 1) 100%);
          background: linear-gradient(135deg, rgba(151, 191, 30, 1) 0%, rgba(240, 240, 240, 1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#97bf1e", endColorstr="#f0f0f0", GradientType=1);
          box-shadow: 0 2px 10px 0 rgba(20, 50, 70, 0.30);
          text-align: center;

          span {
            position: absolute;
            display: inline-block;
            top: 26px;
            left: 15px;
            width: 60px;
            height: 60px;
            transform: rotate(-45deg);
            text-align: center;
            font-family: 'Roboto-Regular';
            color: #464646;
            font-size: .8rem;
            line-height: .85rem;
            letter-spacing: 0;
          }

        }
      }


    }

    .rect-box {
      display: none !important;
      width: 100%;
      height: 100%;
      display: grid;
      grid-gap: 0;
      grid-template-columns: auto;
      grid-template-rows: auto;
      background: #fff url('./img/index-bg.webp') center center / cover;
      z-index: 10;

      .styleimport {
        position: absolute;
        display: inline-block;
        overflow: hidden;
        width: 100% !important;
        transform: translate(-50%, -50%);
        left: 50%;
        height: 40px;
        top: calc(100% - 17px);


      }

      .rect {
        position: relative;
        align-self: center;
        justify-self: center;
        box-sizing: border-box;
        background: transparent;
        transform: rotate(45deg);

        .intro-box {
          position: absolute;
          display: block;
          width: inherit;
          height: inherit;
          overflow: hidden;
          box-sizing: border-box;
          z-index: 100;

          .intro {
            position: absolute;
            top: 50%;
            left: 50%;
            background-image: url('./img/intro.webp');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100%;
            transform: translate(-50%, -50%) rotate(-45deg);
            box-sizing: border-box;
            z-index: 100;

          }


        }

        .seed {
          box-sizing: border-box;
          position: absolute;
          display: block;
          width: inherit;
          height: inherit;
          z-index: 10;


        }



        .link-prod {
          position: absolute;
          display: inline-block;
          top: calc(100% - 17px);
          left: 50%;
          background-color: #229038;
          transform: translate(-50%, -50%);
          cursor: pointer;
          text-align: center;
          display: inline-block;
          width: 280px;
          padding: 10px 20px 10px 20px;
          position: relative;
          height: auto;
          font-family: 'Comfortaa-Regular';
          color: rgb(255, 255, 255);
          font-size: 1em;
          line-height: 1.1em;
          text-decoration: none;
          opacity: .9;
          transition: all 0.3s ease-in-out;


          &:hover {
            background-color: rgba(152, 192, 30, .9);
            color: rgb(255, 255, 255);
            padding: 10px 20px 20px 20px;
            transition: all 0.3s ease-in-out;
          }
        }

        .corobki {

          overflow: hidden;
          background: url('./img/cor1.webp') center center / cover;
        }

        .corobki2 {
          // display: none;
          overflow: hidden;
          background: url('./img/rec2.webp') center center / cover;
        }

        .poketi {

          background: url('./img/pak2.webp') center center / cover;
        }

        .reklama {
          display: block !important;
          background: url('./img/rec2.webp') center center / cover;
        }

        .galery {
          overflow: hidden;
          background: url('./img/galereja.webp') center center / cover;


        }

        .slaid1 {
          width: 100%;
          height: 100%;
          background: url('./img/perec.webp') center center / cover;
        }

        .slaid2 {
          width: 100%;
          height: 100%;
          background: url('./img/comos.webp') center center / cover;
        }

      }
    }

    .bgvideoout {
      display: none !important;
    }

  }
}

@media (max-width: 720px) {
  .Faq {
    position: relative;
    width: 100vw;
    height: auto;
    opacity: 1;
    // background: #fff url('./img/comp.png') center top / cover;
    background-image: url('./img/comp.png');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% auto;
    background-color: #fff;
    z-index: 1;
    box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.10);

    .faq-box {
      width: 100%;
      height: auto;
      background: transparent;
      padding: 90px 0;
      text-align: center;

      .faq-box-question {
        display: inline-block;
        position: relative;
        width: calc(100% - 110px);
        max-width: 900px;
        padding: 0 50px 0 40px;
        height: 80vh;
        background-color: #ffffff;
        border-radius: 5px;
        overflow-y: scroll;
        box-shadow: 0 2px 9px 0 rgba(86, 112, 7, 0.15);

        .question {
          position: relative;
          display: inline-block;
          width: 100%;
          height: auto;
          padding: 20px 0 5px 0;
          text-align: left;

          button {
            width: 100%;
            position: relative;
            display: inline-block;
            border: none;
            border-bottom: 1px solid #E1E1E1;
            margin: 0;
            padding: 0 60px 10px 0;
            margin-bottom: 10px;
            cursor: pointer;
            text-align: left;
            background: transparent;
            font-family: 'Roboto-Regular';
            font-size: 1rem;
            line-height: 1.2rem;
            letter-spacing: .002rem;
            color: #484848;

            &:after {
              position: absolute;
              display: block;
              left: 100%;
              top: 0%;
              transform: translate(-50%, -50%);
              width: 2rem;
              height: 2rem;
              font-size: 1.4rem;
              line-height: 1.9rem;
              content: "+";
              background: #cbcbcb;
              color: #fff;
              text-align: center;
              border-radius: 100%;
              font-family: 'Roboto-Thin';
              z-index: 100;
              transition: all 0.3s ease-in-out;


            }

            &:hover {

              &:after {
                background: #4c4c4c;

                transition: all 0.3s ease-in-out;

              }
            }



          }

          .active {
            font-family: 'Roboto-Bold';

            &:after {
              left: 100%;
              top: 45%;

              background: #4c4c4c;
              transform: rotate(-45deg) translate(-50%, -50%);
              transition: all 0.3s ease-in-out;

            }
          }
        }

        .answer {
          position: relative;
          display: inline-block;
          width: 100%;
          height: 0;
          padding: 0 0;
          text-align: left;
          overflow: hidden;



        }

        .answer-active {
          position: relative;
          display: inline-block;
          width: 100%;
          height: auto;
          padding: 10px 0 30px 0;
          text-align: left;
          overflow: hidden;

        }


      }

    }

    h2 {
      font-family: 'Comfortaa-Bold';
      font-size: 1.5rem;
      line-height: 1.85rem;
      padding: 5px 5px 15px 5px;
      color: #000000;
    }

    p {
      font-family: 'Roboto-Regular';
      font-size: 1rem;
      line-height: 1.2rem;
      letter-spacing: .002rem;
      color: #484848;
    }

    b {
      font-family: 'Roboto-Bold';
      font-size: 1rem;
      line-height: 1.2rem;
      letter-spacing: .002rem;
      color: #484848;
    }

    .table {
      width: 100%;
      max-width: 100%;
      margin-bottom: 1rem;
      background-color: transparent;
      text-align: center;

      tr {
        border: 1px solid #484848;
      }

      th {
        font-family: 'Roboto-Regular';
        font-size: 1rem;
        line-height: 1.2rem;
        letter-spacing: .002rem;
        color: #484848;
        padding: 15px 5px;
        border: 1px solid #484848;
      }

      td {
        font-family: 'Roboto-Regular';
        font-size: 1rem;
        line-height: 1.2rem;
        letter-spacing: .002rem;
        color: #484848;
        padding: 15px 5px;

      }
    }

    a {
      cursor: pointer;
      text-align: center;
      display: inline-block;
      width: auto;
      position: relative;
      height: auto;
      font-family: 'Comfortaa-Regular';
      color: #464646;
      font-size: .87em;
      line-height: 1.1em;
      text-decoration: none;
      opacity: 1;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: #98C01E;
        transition: all 0.3s ease-in-out;
      }


    }
  }

}

@media (max-width: 720px) {
  .contacts {
    .contacts-box {
      position: absolute;
      width: calc(300px - 30px);
      height: auto;
      transform: translateY(-50%);
      padding: 30px 15px;
      left: 0;
      top: calc(100% - 250px);
      background: #fff;
      box-shadow: 0 2px 9px 0 rgba(86, 112, 7, 0.15);
      border-radius: 3px;
      transition: all 0.3s ease-in-out;

      .contacts-descr {
        width: calc(100% - 20px);
        padding: 0 10px;
        align-self: start;
        justify-self: center;
        text-align: center;
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr;
        grid-template-rows: auto;


        .adres {
          display: grid;
          grid-gap: 30px;
          grid-template-columns: 1fr;
          grid-template-rows: auto;
          text-align: left;
        }

        .contatc {
          display: grid;
          grid-gap: 5px;
          grid-template-columns: 1fr;
          grid-template-rows: auto;
          text-align: left;

          a {
            text-align: left;
          }
        }

        button {
          width: 100%;
          position: relative;
          display: inline-block;
          border: none;
          margin: 0;
          left: calc(100% - 20px);
          top: 0;
          cursor: pointer;
          text-align: right;
          background: transparent;
          width: 2rem;
          height: 2rem;

          &:after {
            position: absolute;
            display: block;
            left: calc(50% - 1rem);
            top: calc(50% - 1rem);
            transform: rotate(0);
            transform-origin: center;
            width: 2rem;
            height: 2rem;
            font-size: 2rem;
            line-height: 2rem;
            content: "+";
            background: #cbcbcb;
            color: #fff;
            text-align: center;
            border-radius: 100%;
            font-family: 'Roboto-Thin';
            z-index: 100;
            transition: all 0.3s ease-in-out;
          }

          &:hover {

            &:after {
              background: #4c4c4c;
              transition: all 0.3s ease-in-out;

            }
          }



        }

        .active {
          font-family: 'Roboto-Bold';

          &:after {
            background: #4c4c4c;
            transform: rotate(-45deg);
            transform-origin: center;
            transition: all 0.3s ease-in-out;

          }
        }



      }


    }

    .cont-active {
      transition: all 0.3s ease-in-out;
      left: -220px;
      height: auto;
    }

    h2 {
      font-family: 'Comfortaa-Bold';
      font-size: 1.6rem;
      line-height: 2rem;
      padding: 10px 5px;
      color: #000000;
    }

    p {
      font-family: 'Roboto-Regular';
      font-size: .87rem;
      line-height: 1rem;
      letter-spacing: .002rem;
      color: #484848;
    }

    b {
      font-family: 'Roboto-Bold';
      font-size: .87rem;
      line-height: 1rem;
      letter-spacing: .002rem;
      color: #484848;
    }

    .table {
      width: 100%;
      max-width: 100%;
      margin-bottom: 1rem;
      background-color: transparent;
      text-align: center;

      tr {
        border: 1px solid #484848;
      }

      th {
        font-family: 'Roboto-Regular';
        font-size: 1rem;
        line-height: 1.2rem;
        letter-spacing: .002rem;
        color: #484848;
        padding: 15px 5px;
        border: 1px solid #484848;
      }

      td {
        font-family: 'Roboto-Regular';
        font-size: 1rem;
        line-height: 1.2rem;
        letter-spacing: .002rem;
        color: #484848;
        padding: 15px 5px;

      }
    }

    a {
      cursor: pointer;
      text-align: center;
      display: inline-block;
      width: auto;
      position: relative;
      height: auto;
      font-family: 'Comfortaa-Regular';
      color: #464646;
      font-size: .87em;
      line-height: 1.1em;
      text-decoration: none;
      opacity: 1;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: #98C01E;
        transition: all 0.3s ease-in-out;
      }


    }
  }
}

@media (max-width: 350px) {
  .contacts {
    .contacts-box {
      position: absolute;
      width: calc(300px - 30px);
      height: auto;
      transform: translateY(-50%);
      padding: 30px 15px;
      left: 0;
      top: calc(50% - 20px);
      background: #fff;
      box-shadow: 0 2px 9px 0 rgba(86, 112, 7, 0.15);
      border-radius: 3px;
      transition: all 0.1s ease-in-out;

      .contacts-descr {
        width: calc(100% - 40px);
        padding: 0 20px;
        align-self: start;
        justify-self: center;
        text-align: center;
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        opacity: 1;


        .adres {
          display: grid;
          grid-gap: 30px;
          grid-template-columns: 1fr;
          grid-template-rows: auto;
          text-align: left;
          opacity: 1;
        }

        .contatc {
          display: grid;
          grid-gap: 5px;
          grid-template-columns: 1fr;
          grid-template-rows: auto;
          text-align: left;
          opacity: 1;

          .for-mob {
            display: inline-block;
          }

          a {
            text-align: left;
          }
        }

        button {
          width: 100%;
          position: relative;
          display: inline-block;
          border: none;
          margin: 0;
          left: calc(100% - 20px);
          top: 0;
          cursor: pointer;
          text-align: right;
          background: transparent;
          width: 2rem;
          height: 2rem;

          &:after {
            position: absolute;
            display: block;
            left: calc(50% - 1rem);
            top: calc(50% - 1rem);
            transform: rotate(0);
            transform-origin: center;
            width: 2rem;
            height: 2rem;
            font-size: 2rem;
            line-height: 2rem;
            content: "+";
            background: #cbcbcb;
            color: #fff;
            text-align: center;
            border-radius: 100%;
            font-family: 'Roboto-Thin';
            z-index: 100;
            transition: all 0.3s ease-in-out;
          }

          &:hover {

            &:after {
              background: #4c4c4c;
              transition: all 0.3s ease-in-out;

            }
          }



        }

        .active {
          font-family: 'Roboto-Bold';

          &:after {
            background: #4c4c4c;
            transform: rotate(-45deg);
            transform-origin: center;
            transition: all 0.3s ease-in-out;

          }
        }



      }


    }

    .cont-active {
      top: calc(50% - 20px);
      transition: all 0.3s ease-in-out;
      left: -220px;
      height: 30px;
      overflow: hidden;

      .contacts-descr {
        .adres {
          display: grid;
          grid-gap: 30px;
          grid-template-columns: 1fr;
          grid-template-rows: auto;
          text-align: left;
          opacity: 0;
          transition: all 0.1s ease-in-out;
        }

        .contatc {
          display: grid;
          grid-gap: 5px;
          grid-template-columns: 1fr;
          grid-template-rows: auto;
          text-align: left;
          opacity: 0;
          transition: all 0.1s ease-in-out;

          a {
            text-align: left;
          }
        }
      }
    }

    h2 {
      font-family: 'Comfortaa-Bold';
      font-size: 1.6rem;
      line-height: 2rem;
      padding: 10px 5px;
      color: #000000;
    }

    p {
      font-family: 'Roboto-Regular';
      font-size: .9rem;
      line-height: 1rem;
      letter-spacing: .002rem;
      color: #484848;
    }

    b {
      font-family: 'Roboto-Bold';
      font-size: .87rem;
      line-height: 1rem;
      letter-spacing: .002rem;
      color: #484848;
    }

    .table {
      width: 100%;
      max-width: 100%;
      margin-bottom: 1rem;
      background-color: transparent;
      text-align: center;

      tr {
        border: 1px solid #484848;
      }

      th {
        font-family: 'Roboto-Regular';
        font-size: 1rem;
        line-height: 1.2rem;
        letter-spacing: .002rem;
        color: #484848;
        padding: 15px 5px;
        border: 1px solid #484848;
      }

      td {
        font-family: 'Roboto-Regular';
        font-size: 1rem;
        line-height: 1.2rem;
        letter-spacing: .002rem;
        color: #484848;
        padding: 15px 5px;

      }
    }

    a {
      cursor: pointer;
      text-align: center;
      display: inline-block;
      width: auto;
      position: relative;
      height: auto;
      font-family: 'Comfortaa-Regular';
      color: #464646;
      font-size: .87em;
      line-height: 1.1em;
      text-decoration: none;
      opacity: 1;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: #98C01E;
        transition: all 0.3s ease-in-out;
      }


    }
  }
}

@media (max-width: 490px) {

  .comp {
    position: relative;
    width: 100vw;
    height: calc(100vh - 60px);
    opacity: 1;
    background-color: #fff;
    background-image: url('./img/comp.webp');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    z-index: 1;
    box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.10);

    .comp-box {
      position: relative;
      display: grid;
      width: 100%;
      padding-top: 50px;
      grid-gap: 30px;
      height: calc(100% - 50px);
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;

      .comp-box-info {
        align-self: center;
        justify-self: center;
        width: calc(100% - 30px);
        max-width: 1200px;
        padding: 15px 0;
        grid-gap: 30px;
        height: auto;
        background-color: rgba(#fff, .8);
        border-radius: 5px;
        box-shadow: 0 2px 9px 0 rgba(86, 112, 7, 0.15);
        -webkit-backdrop-filter: blur(3px);
        backdrop-filter: blur(3px);

        .comp-info {
          align-self: center;
          justify-self: center;
          text-align: center;
          position: relative;
          width: calc(100% - 20px);
          padding: 10px;
          height: auto;


          h2 {
            font-family: 'Comfortaa-Bold';
            font-size: 1.2rem;
            line-height: 1.35rem;
            padding: 0 5px 5px 5px;
            color: #000000;
            transition: all .3s ease-in-out;
          }

          p {
            font-family: 'Roboto-Regular';
            font-size: .8rem;
            line-height: 1rem;
            letter-spacing: .002rem;
            color: #484848;
          }

          b {
            font-family: 'Roboto-Bold';
            font-size: .8rem;
            line-height: 1rem;
            letter-spacing: .002rem;
            color: #484848;
          }

          .table {
            width: 100%;
            max-width: 100%;
            margin-bottom: 1rem;
            background-color: transparent;
            text-align: center;

            tr {
              border: 1px solid #484848;
            }

            th {
              font-family: 'Roboto-Regular';
              font-size: 1rem;
              line-height: 1.2rem;
              letter-spacing: .002rem;
              color: #484848;
              padding: 15px 5px;
              border: 1px solid #484848;
            }

            td {
              font-family: 'Roboto-Regular';
              font-size: 1rem;
              line-height: 1.2rem;
              letter-spacing: .002rem;
              color: #484848;
              padding: 15px 5px;

            }
          }

          a {
            cursor: pointer;
            text-align: center;
            display: inline-block;
            width: auto;
            position: relative;
            height: auto;
            font-family: 'Comfortaa-Regular';
            color: #464646;
            font-size: 1em;
            line-height: 1.25em;
            text-decoration: none;
            opacity: 1;
            transition: all 0.3s ease-in-out;

            &:hover {
              color: #98C01E;
              transition: all 0.3s ease-in-out;
            }


          }
        }






      }
    }
  }

}

@media (max-width: 490px) {
  .box-prodact {
    position: relative;
    margin-top: 60px;
    display: block;
    width: 100vw;
    height: calc(100vh - 60px);
    overflow: hidden;
  }
}

@media (max-width: 960px) {
  .page {
    position: relative;
    display: block;
    width: 100vw;
    height: 100vh;
    overflow: hidden;


    .slide-box {
      position: absolute;
      display: inline-block;
      width: 1px;
      height: 1px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      z-index: 1;

      .smol1 {
        position: absolute;
        width: 250px;
        height: 250px;
        top: 50%;
        left: calc(50% - 360px);
        filter: blur(2px);
        transform: translate(-50%, -50%);
        overflow: hidden;
        transition: all .8s ease-in-out;

        img {
          position: relative;
          display: inline-block;
          width: calc(100% - 10px);
          height: auto;

          z-index: 20;
        }

        .description {
          position: absolute;

          top: 100%;
          opacity: 0;
        }




      }

      .smol2 {
        position: absolute;
        width: 200px;
        height: 200px;
        top: 50%;
        left: calc(50% - 600px);
        transform: translate(-50%, -50%);
        filter: blur(5px);
        overflow: hidden;
        transition: all .5s ease-in-out;

        .description {
          position: absolute;

          top: 100%;
          opacity: 0;
        }

        img {
          position: relative;
          display: inline-block;
          width: calc(100% - 10px);
          height: auto;
          z-index: 20;
        }


      }

      .big {

        position: absolute;
        display: block;
        width: 550px;
        height: 550px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: transparent;

        z-index: 10;
        overflow: hidden;
        transition: all .8s ease-in-out;

        img {
          position: relative;
          display: inline-block;
          width: calc(100% - 20px);
          height: auto;
          z-index: 20;
          transition: all .3s ease-in-out
        }

        .description {
          transition: all .8s ease-in-out
        }
      }

      .smol3 {
        position: absolute;
        width: 250px;
        height: 250px;
        top: 50%;
        left: calc(50% + 360px);
        transform: translate(-50%, -50%);
        filter: blur(2px);

        .description {
          opacity: 0;

        }

        transition: all .5s ease-in-out;
        overflow: hidden;



        img {
          position: relative;
          display: inline-block;
          width: calc(100% - 10px);
          height: auto;
          z-index: 20;
        }


      }

      .smol4 {
        position: absolute;
        width: 200px;
        height: 200px;
        top: 50%;
        left: calc(50% + 600px);
        transform: translate(-50%, -50%);
        transition: all .85s ease-in-out;
        overflow: hidden;
        filter: blur(5px);

        .description {
          opacity: 0;

        }

        img {
          position: relative;
          display: inline-block;
          width: calc(100% - 10px);
          height: auto;

          z-index: 20;
        }
      }

      .no-smol {
        position: absolute;
        width: 200px;
        height: 200px;
        top: 50%;
        left: 200%;
        transform: translate(-50%, -50%);

        overflow: hidden;
        filter: blur(5px);
        opacity: 0;

        .description {
          opacity: 0;

        }

        img {
          position: relative;
          display: inline-block;
          width: calc(100% - 10px);
          height: auto;

          z-index: 20;
        }
      }

      .description {
        opacity: .9;
        position: relative;
        display: inline-block;
        width: calc(100% - 30px);
        max-width: 320px;
        padding: 15px;
        top: -20%;
        left: 40%;
        transform: translate(-50%, -50%);
        height: auto;
        background-color: #fff;
        border-radius: 3px;
        z-index: 50;
        box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.10);

        .btn {
          cursor: pointer;
          position: relative;
          display: inline-block;
          width: 120px;
          height: 40px;
          margin-top: 10px;
          border-radius: 3px;
          border: 0;
          background-color: #229038;
          color: #fff;
          font-size: .87rem;
          line-height: 1.1rem;
          z-index: 50;
          box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.10);
          transition: all .3s ease-in-out;

          &:hover {
            color: rgba(#fff, .5);
            box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.40);
            background-position: 60% 50%;
            transition: all .3s ease-in-out;

          }
        }

        h3 {
          font-family: 'Comfortaa-Regular';
          font-size: 1.2rem;
          line-height: 1.8rem;
          color: #229038;
        }

        p {
          color: #333;
          font-family: "Roboto-Regular";
          font-size: .9rem;
          line-height: 1.1rem;
          letter-spacing: 0.01rem;
          padding: 3px 5px;
        }
      }
    }



    .left {
      cursor: pointer;
      position: absolute;
      display: inline-block;
      width: 60px;
      height: 60px;
      top: 50%;
      left: 100px;
      border-radius: 100%;
      background-image: url('./img/left.svg');
      background-repeat: no-repeat;
      background-position: 55% 50%;
      background-size: 20px;
      transform: translate(-50%, -50%);

      border: 0;
      background-color: #fff;
      z-index: 50;
      box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.10);
      transition: all .3s ease-in-out;


      &:hover {

        animation: none;
        box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.40);
        background-position: 60% 50%;
        transition: all .3s ease-in-out;

      }
    }

    .right {
      cursor: pointer;
      position: absolute;
      display: inline-block;
      width: 60px;
      height: 240px;
      top: 50%;
      left: calc(100% - 40px);
      transform: translate(-50%, -50%);
      border: 0;
      background: transparent;
      z-index: 20;

      .romb {
        position: absolute;
        display: inline-block;
        width: 50px;
        height: 240px;
        font-size: .87rem;
        line-height: 3rem;
        letter-spacing: .05rem;
        color: #fff;
        border-radius: 3px;
        top: 0;
        left: 20px;
        background-color: rgba(#229038, .7);
        writing-mode: vertical-rl;
        border: .005rem solid #fff;
        transition: all .3s ease-in-out;
      }

      &:hover {
        .romb {
          border: .005rem solid rgba(#fff, .7);
          letter-spacing: .0rem;
          transition: all .3s ease-in-out;
        }
      }
    }

    .bg-text {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-radius: 100%;
      left: 50%;
      top: 50%;
      z-index: 1000;
      transform: translate(-50%, -50%);
      background-color: rgba(#229038, .7);
      transition: all 0s ease-in-out;
    }

    .bg-text-active {
      position: absolute;
      display: block;
      width: 200vw;
      height: 200vw;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 0%;
      top: 0;
      left: 50%;
      top: 50%;
      z-index: 3000;
      background-color: rgba(#229038, .7);
      transition: all 0s ease-in-out;
    }

    .text-desription-box {
      position: absolute;
      display: block;
      width: calc(100vw - 40px);
      height: auto;
      left: -100%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      z-index: 4000;
      opacity: 0;
      box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.10);
      transition: all .3s ease-in-out;

      .text-desription {
        display: block;
        margin: 40px auto;
        width: calc(100% - 40px);
        padding: 20px;
        max-width: 900px;

        h3 {
          font-family: 'Comfortaa-Regular';
          font-size: 2rem;
          line-height: 3rem;
          color: #229038;
        }

        p {
          color: #333;
          font-family: "Roboto-Regular";
          font-size: 1rem;
          line-height: 1.3rem;
          letter-spacing: 0.01rem;
          padding: 3px 5px;
        }
      }

      .close {
        cursor: pointer;
        position: absolute;
        display: inline-block;
        width: 70px;
        height: 70px;
        top: 10px;
        right: 10px;
        background-color: transparent;
        background-image: url('./img/close-b.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        border: .005rem solid #fff;
        z-index: 20;

        transition: all .3s ease-in-out;

        &:hover {
          transition: all .3s ease-in-out;
          background-size: 90%;
        }
      }
    }

    .text-desription-box-active {
      position: absolute;
      border-radius: 3px;
      display: block;
      width: calc(100vw - 60px);
      height: auto;
      left: 50%;
      top: calc(50% - 60px);
      transform: translate(-50%, -50%);
      background-color: #fff;
      z-index: 4000;
      opacity: 1;
      box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.10);
      animation: textmove .6s ease-in-out;




      .close {
        cursor: pointer;
        position: absolute;
        display: inline-block;
        width: 70px;
        height: 70px;
        top: 10px;
        right: 10px;
        background-color: transparent;
        background-image: url('./img/close-b.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        border: .005rem solid #fff;
        z-index: 20;

        transition: all .3s ease-in-out;

        &:hover {
          transition: all .3s ease-in-out;
          background-size: 90%;
        }
      }

      .text-desription {
        display: block;
        margin: 0 auto;
        width: calc(100% - 40px);
        // height: calc(100vh - 200px);
        height: auto;
        padding: 20px;
        max-width: 900px;
        overflow: hidden;

        .text-desription-scroll {
          position: relative;
          display: block;
          margin: 0 auto;
          width: calc(100% - 10px);
          padding: 5px 5px 45px 5px;
        }

        h3 {
          font-family: 'Comfortaa-Regular';
          font-size: 2rem;
          line-height: 3rem;
          color: #229038;
        }

        p {
          color: #333;
          font-family: "Roboto-Regular";
          font-size: 1rem;
          line-height: 1.3rem;
          letter-spacing: 0.01rem;
          padding: 3px 5px;
        }


      }
    }
  }
}

@media (max-width: 460px) {
  .page {
    position: relative;
    display: block;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    .slide-box {
      position: absolute;
      display: inline-block;
      width: 1px;
      height: 1px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;

      .smol1 {
        position: absolute;
        width: 250px;
        height: 250px;
        top: 50%;
        left: calc(50% - 360px);
        filter: blur(2px);
        transform: translate(-50%, -50%);
        overflow: hidden;
        transition: all .5s ease-in-out;

        img {
          position: relative;
          display: inline-block;
          width: calc(100% - 10px);
          height: auto;

          z-index: 20;
        }

        .description {
          position: absolute;

          top: 100%;
          opacity: 0;
        }




      }

      .smol2 {
        position: absolute;
        width: 200px;
        height: 200px;
        top: 50%;
        left: calc(50% - 600px);
        transform: translate(-50%, -50%);
        filter: blur(5px);
        overflow: hidden;
        transition: all .3s ease-in-out;

        .description {
          position: absolute;

          top: 100%;
          opacity: 0;
        }

        img {
          position: relative;
          display: inline-block;
          width: calc(100% - 10px);
          height: auto;
          z-index: 20;
        }


      }

      .big {
        position: absolute;
        display: block;
        width: 100vw;
        height: 100vh;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: transparent;
        z-index: 10;
        overflow: visible;
        transition: all .6s ease-in-out;
        text-align: center;

        img {
          position: relative;
          display: inline-block;
          width: calc(100% - 30px);
          margin-top: 120px;
          height: auto;
          z-index: 20;
          transition: all .3s ease-in-out
        }

        .description {
          transition: all .8s ease-in-out
        }
      }

      .smol3 {
        position: absolute;
        width: 250px;
        height: 250px;
        top: 50%;
        left: calc(50% + 360px);
        transform: translate(-50%, -50%);
        filter: blur(2px);

        .description {
          opacity: 0;

        }

        transition: all .3s ease-in-out;
        overflow: hidden;



        img {
          position: relative;
          display: inline-block;
          width: calc(100% - 10px);
          height: auto;
          z-index: 20;
        }


      }

      .smol4 {
        position: absolute;
        width: 200px;
        height: 200px;
        top: 50%;
        left: calc(50% + 600px);
        transform: translate(-50%, -50%);
        transition: all .5s ease-in-out;
        overflow: hidden;
        filter: blur(5px);

        .description {
          opacity: 0;

        }

        img {
          position: relative;
          display: inline-block;
          width: calc(100% - 10px);
          height: auto;

          z-index: 20;
        }
      }

      .no-smol {
        position: absolute;
        width: 200px;
        height: 200px;
        top: 50%;
        left: 200%;
        transform: translate(-50%, -50%);
        transition: all .5s ease-in-out;
        overflow: hidden;
        filter: blur(5px);
        opacity: 0;

        .description {
          opacity: 0;

        }

        img {
          position: relative;
          display: inline-block;
          width: calc(100% - 10px);
          height: auto;

          z-index: 20;
        }
      }

      .description {
        opacity: 1;
        position: relative;
        display: inline-block;
        width: calc(100% - 30px);
        max-width: 260px;
        padding: 15px;
        top: -10%;
        left: calc(0%);
        transform: none;
        height: auto;
        background-color: rgba(#fff, .8);
        border-radius: 3px;

        z-index: 50;
        box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.10);

        .btn {
          cursor: pointer;
          position: relative;
          display: inline-block;
          width: 120px;
          height: 40px;
          margin-top: 10px;
          border-radius: 3px;
          border: 0;
          background-color: #229038;
          color: #fff;
          font-size: .87rem;
          line-height: 1.1rem;
          z-index: 50;
          box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.10);
          transition: all .3s ease-in-out;

          &:hover {
            color: rgba(#fff, .5);
            box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.40);
            background-position: 60% 50%;
            transition: all .3s ease-in-out;

          }
        }

        h3 {
          font-family: 'Comfortaa-Regular';
          font-size: 1.6rem;
          line-height: 1.8rem;
          color: #229038;
        }

        p {
          color: #333;
          font-family: "Roboto-Regular";
          font-size: .9rem;
          line-height: 1.1rem;
          letter-spacing: 0.01rem;
          padding: 3px 5px;
        }
      }
    }



    .left {
      cursor: pointer;
      position: absolute;
      display: inline-block;
      width: 60px;
      height: 60px;
      top: 40%;
      left: 40px;
      border-radius: 100%;
      background-image: url('./img/left.svg');
      background-repeat: no-repeat;
      background-position: 55% 50%;
      background-size: 20px;
      transform: translate(-50%, -50%);

      border: 0;
      background-color: #fff;
      z-index: 50;
      box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.10);
      transition: all .3s ease-in-out;


      &:hover {

        animation: none;
        box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.40);
        background-position: 60% 50%;
        transition: all .3s ease-in-out;

      }
    }

    .right {
      cursor: pointer;
      position: absolute;
      display: inline-block;
      width: 60px;
      height: 240px;
      top: calc(100% - 250px);
      left: calc(100% - 40px);
      transform: translate(-50%, -50%);
      border: 0;
      background: transparent;
      z-index: 20;
      opacity: 0;

      .romb {
        position: absolute;
        display: inline-block;
        width: 50px;
        height: 240px;
        font-size: .87rem;
        line-height: 3rem;
        letter-spacing: .05rem;
        color: #fff;
        border-radius: 3px;
        top: 0;
        left: 20px;
        background-color: rgba(#229038, .7);
        writing-mode: vertical-rl;
        border: .005rem solid #fff;
        transition: all .3s ease-in-out;
      }

      &:hover {
        .romb {
          border: .005rem solid rgba(#fff, .7);
          letter-spacing: .0rem;
          transition: all .3s ease-in-out;
        }
      }
    }

    .bg-text {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-radius: 100%;
      left: 50%;
      top: 50%;
      z-index: 1000;
      transform: translate(-50%, -50%);
      background-color: rgba(#229038, .7);
      transition: all 0s ease-in-out;
    }

    .bg-text-active {
      position: absolute;
      display: block;
      width: 200vw;
      height: 200vw;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 0%;
      top: 0;
      left: 50%;
      top: 50%;
      z-index: 3000;
      background-color: rgba(#229038, .7);
      transition: all 0s ease-in-out;
    }

    .text-desription-box {
      position: absolute;
      display: block;
      width: calc(100vw - 40px);
      height: auto;
      left: -100%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      z-index: 4000;
      opacity: 0;
      box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.10);
      transition: all .3s ease-in-out;

      .text-desription {
        display: block;
        margin: 40px auto;
        width: calc(100% - 40px);
        padding: 20px;
        max-width: 900px;

        h3 {
          font-family: 'Comfortaa-Regular';
          font-size: 2rem;
          line-height: 3rem;
          color: #229038;
        }

        p {
          color: #333;
          font-family: "Roboto-Regular";
          font-size: 1rem;
          line-height: 1.3rem;
          letter-spacing: 0.01rem;
          padding: 3px 5px;
        }
      }

      .close {
        cursor: pointer;
        position: absolute;
        display: inline-block;
        width: 70px;
        height: 70px;
        top: 10px;
        right: 10px;
        background-color: transparent;
        background-image: url('./img/close-b.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        border: .005rem solid #fff;
        z-index: 20;

        transition: all .3s ease-in-out;

        &:hover {
          transition: all .3s ease-in-out;
          background-size: 90%;
        }
      }
    }

    .text-desription-box-active {
      position: absolute;
      border-radius: 3px;
      display: block;
      width: calc(100vw - 60px);
      height: auto;
      left: 50%;
      top: calc(50% - 60px);
      transform: translate(-50%, -50%);
      background-color: #fff;
      z-index: 4000;
      opacity: 1;
      box-shadow: 0 2px 20px 0 rgba(20, 50, 70, 0.10);
      animation: textmove .6s ease-in-out;

      .close {
        cursor: pointer;
        position: absolute;
        display: inline-block;
        width: 70px;
        height: 70px;
        top: 10px;
        right: 10px;
        background-color: transparent;
        background-image: url('./img/close-b.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        border: .005rem solid #fff;
        z-index: 20;

        transition: all .3s ease-in-out;

        &:hover {
          transition: all .3s ease-in-out;
          background-size: 90%;
        }
      }

      .text-desription {
        display: block;
        margin: 40px auto 0px auto;
        width: calc(100% - 40px);
        // height: calc(100vh - 200px);
        height: auto;
        padding: 20px;
        max-width: 900px;
        overflow: hidden;

        .text-desription-scroll {
          position: relative;
          display: block;
          margin: 0 auto;
          width: calc(100% - 10px);
          padding: 5px 5px 45px 5px;
        }

        h3 {
          font-family: 'Comfortaa-Regular';
          font-size: 2rem;
          line-height: 3rem;
          color: #229038;
        }

        p {
          color: #333;
          font-family: "Roboto-Regular";
          font-size: 1rem;
          line-height: 1.3rem;
          letter-spacing: 0.01rem;
          padding: 3px 5px;
        }


      }
    }
  }
}


@keyframes textmove {
  0% {
    left: -100%;
    opacity: 0;
  }

  15% {

    left: -60%;
    opacity: 0;

  }

  30% {
    left: -55%;
    opacity: .5;


  }


  100% {
    left: 50%;
    opacity: 1;
  }
}

@keyframes buttonleft {
  0% {
    background-position: 80% 20%;
  }

  50% {

    background-position: 85% 15%;

  }

  100% {
    background-position: 80% 20%;
  }
}